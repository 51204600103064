import { render, staticRenderFns } from "./email.vue?vue&type=template&id=ce645b12&scoped=true"
import script from "./email.vue?vue&type=script&lang=js"
export * from "./email.vue?vue&type=script&lang=js"
import style0 from "./email.vue?vue&type=style&index=0&id=ce645b12&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce645b12",
  null
  
)

export default component.exports