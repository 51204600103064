// initial state
const state = () => ({
  user: {},
  lti: {}
})

// getters
const getters = {
  getUserDetails: (state) => {
    return state.user;
  },
  getUserOrgAndLMSLink: (state) => {
    return state.lti;
  } 
}

// actions
const actions = {
  setUserData({ commit }, result) {
    commit('setUser', JSON.parse(result));
  },
  setUserProfileData( { commit }, data) {
    commit('updateUserData', JSON.parse(data));
  },
  setUserSSOTerms({ commit }) {
    commit('updateUserSSOTerms');
  },
  setUserSectionAccess({ commit }, data) {
    commit('updateUserSectionAccess', data);
  },
  setUserOrgAndLMSLink({ commit }, result) {
    commit('setUserOrgAndLMS', JSON.parse(result));
  },
  logout({ commit }) {
    commit('destroyUserState');
  }
}


// mutations
const mutations = {
  setUser(state, result) {
    state.user = result.users;
    state.user.authorization_token = result.authorization_token;
  },
  setUserOrgAndLMS(state, result) {
    state.lti.organization_name = result.users.attributes.organization_name;
    state.lti.lms_link = result.users.attributes.student_access_url;
    state.lti.saml_connection = result.users.attributes.saml_connection;
  },
  updateUserData(state, data) {
    state.user.attributes.first_name = data.data.attributes.first_name;
    state.user.attributes.last_name = data.data.attributes.last_name;
    state.user.attributes.email = data.data.attributes.email;
    state.user.attributes.profile_url = data.data.attributes.profile_url
  },
  updateUserSSOTerms(state) {
    state.user.attributes.sso_accept_terms = true;
  },
  updateUserSectionAccess(state, data) {
    state.user.no_access_us_ids = data;
  },
  destroyUserState(state) {
    state.user = {};
    localStorage.removeItem("learner");
    localStorage.removeItem("learner_sso");
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}