import axios from 'axios'
import { utilFunctionService } from "@/utils/utils.service"

const SERVER_URL = process.env.VUE_APP_URL
console.log("printing server URL...");
console.log(SERVER_URL);
const instance = axios.create({
  baseURL: SERVER_URL
});

export default {
  
  async execute(method, resource, data, config) {
    let accessToken = utilFunctionService.getAuthToken();
    return instance({
      method: method,
      url: resource,
      data,
      headers: {
        Authorization: `${accessToken}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, X-Auth-Token"
      },
      ...config
    })
  },
  async executeFormData(method, resource, data, config) {
    let accessToken = utilFunctionService.getAuthToken();
    return instance({
      method: method,
      url: resource,
      data,
      headers: {
        Authorization: `${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      ...config
    })
  },

  loginUser(user) {
    let _result = this.execute('POST', '/v1/users/login.json', { user })
    return _result
  },
  loginLtiUser(form) {
    console.log("*** hit loginLtiUser in api.js");
    let _result = this.execute('POST', '/v1/users/login.json', form)
    return _result
  },
  loginSamlUser() {
    console.log("*** hit loginSamlUser in api.js");
    let _result = this.execute('GET', '/v1/saml/login.json')
    return _result
  },
  sendPasswordResetMail(form){
    return this.execute('POST', `/v1/password/forgot.json`, form);
  },
  verifyToken(token){
    return this.execute('GET', `/v1/password/verify_token.json?token=${token}`)
  },
  resetPassword(form){
    return this.execute('POST', `/v1/password/reset.json`, form)
  },
  postRegister(form) {
    return this.execute('POST', `/v1/learner_registrations.json`, form);
  },
  postPaymentTest(form) {
    return this.execute('POST', `/v1/test_payment.json`, form);
  },
  postPurchase(form) {
    return this.execute('POST', `/v1/learner_purchase.json`, form);
  },
  dashboard() {
    return this.execute('GET', '/v1/learner_dashboards.json')
  },
  getList(type) {
   return this.execute('GET', `/v1/modules.json?type=${type}`)
  },
  getMenu(id, time, user_id) {
    return this.execute('GET', `/v1/modules/${id}/module_details.json?current_timestamp=${time}&user_id=${user_id}`);
  },
  getMenuDetails(id, module_id, prev_module_id) {
    return this.execute('GET', `/v1/modules/${id}/module_details/${module_id}.json?prev_module_id=${prev_module_id}`);
  },
  updateLOStatus(module_id,id ,data) {
    return this.execute('PATCH', `/v1/modules/${module_id}/module_details/${id}.json`,data);
  },
  quizQuestions(id, loId) {
    return this.execute('GET', `/v1/quiz_questions.json?id=${id}&user_lo_id=${loId}`);
  },
  postQuizResponse(form) {
    return this.execute('POST', `/v1/quiz_evaluations.json`, form);
  },
  patchQuizResponse(form, id) {
    return this.execute('PATCH', `/v1/quiz_evaluations/${id}.json`, form);
  },
  getQuizResponse(id) {
    return this.execute('GET', `/v1/quiz_evaluations/${id}.json`);
  },
  getDialogicAnswers(id) {
    return this.execute('GET', `/v1/dialogic_evaluations/${id}/dialogic_answers.json`);
  },
  getDialogicDraftAnswer(id, question_id) {
    return this.execute('GET', `/v1/dialogic_evaluations/${id}/dialogic_answers/draft_answer/${question_id}.json`);
  },
  saveDialogicAnswers(id, form) {
    return this.execute('POST', `/v1/dialogic_evaluations/${id}/dialogic_answers.json`,form);
  },
  getdialogicQuestions(dilogic_learn_obj_id,user_lo_id,retry) {
    return this.execute('GET', `/v1/dialogic_questions.json?dilogic_learn_obj_id=${dilogic_learn_obj_id}&user_lo_id=${user_lo_id}&retry=${retry}`);
  },
  dialogicEvaluations(id) {
    return this.execute('GET', `/v1/dialogic_evaluations/${id}.json`);
  },
  postUserSubmission(form) {
    return this.execute('POST', `/v1/user_submissions.json`, form);
  },
  getSubmissionData(id){
    return this.execute('GET', `/v1/user_submissions/${id}.json`);
  },
  updateUserSubmission(id, form){
    return this.execute('PATCH', `/v1/user_submissions/${id}.json`, form);
  },
  createUserChats(form) {
    return this.execute('POST', `/v1/user_chats.json`, form);
  },
  getUserChatMessages(id) {
      return this.execute('GET', `/v1/user_chat_messages.json?user_chat_id=${id}`);
  },
  sendChatMessages(fb) {
    return this.execute('POST', `/v1/user_chat_messages.json`,fb);
  },
  evaluateChat(id) {
    return this.execute('POST', `/v1/user_chats/${id}/evaluate.json`);
  },
  getEvaluateChat(id) {
    return this.execute('GET', `/v1/user_chats/${id}.json`);
  },
  resetEmailInteraction(id) {
    return this.execute('PATCH', `/v1/user_email_evaluations/${id}.json`);
  },
  resetDialogicInteraction(id) {
    return this.execute('POST', `/v1/dialogic_evaluations/${id}/reset_dialogic.json`);
  },
  resetCodingInteraction(id) {
    return this.execute('POST', `/v1/user_coding_evaluations/${id}/reset_interaction.json`);
  },
  resetQuizInteraction(id) {
    return this.execute('POST', `/v1/quiz_evaluations/${id}/reset_quiz.json`);
  },
  createEvaluateDialogic(id) {
    return this.execute('POST', `/v1/dialogic_evaluations/${id}/evaluate.json`);
  },
  sendEmailResponse(id, form) {
    return this.execute('POST', `/v1/user_email_evaluations/${id}/user_email_iterations.json`, form);
  },
  getDraftEmail(id) {
    return this.execute('GET', `/v1/user_email_evaluations/${id}/draft_email.json`);
  },
  getEmailList(id) {
    return this.execute('GET', `/v1/user_email_evaluations/${id}/user_email_iterations.json`);
  },
  emailFeedbackResponse(id) {
    return this.execute('GET', `/v1/user_email_evaluations/${id}.json`);
  },
  finalEvaluation(id) {
    return this.execute('GET', `/v1/modules/${id}/module_details/final_evaluation.json`);
  },
  getCoC(id) {
    return this.execute('GET', `/v1/modules/${id}/module_details/show_certificate_of_completion.json`);
  },
  resetSubmissionEmail(id) {
    return this.execute('POST', `/v1/modules/${id}/module_details/reset_submission_email.json`);
  },
  resetSubmission(id) {
    return this.execute('POST', `/v1/modules/${id}/module_details/reset_submission.json`);
  },
  finalEvaluationPDF(id) {
    return this.execute('GET', `/v1/modules/${id}/module_details/download_final_eval_pdf.json`);
  },
  downloadCertificateOfCompletion(id) {
    return this.execute('GET', `/v1/modules/${id}/module_details/download_certificate_of_completion.json`);
  },
  getEmailSubmissions(id) {
    return this.execute('GET', `/v1/user_email_submissions.json?id=${id}`);
  },
  postEmailSubmissions(fb) {
    return this.execute('POST', `/v1/user_email_submissions.json`,fb);
  },
  getSubmissionEmailDebrief(id) {
    return this.execute('GET', `/v1/user_submissions/${id}/evaluation.json`);
  },
  getSubmissionEmailEvaluation(id ,resp_id) {
    return this.execute('GET', `/v1/user_email_submissions/evaluation.json?id=${id}&email_sub_resp_id=${resp_id}`);
  },
  getDraftSubmissionEmail(id){
    return this.execute('GET', `/v1/user_email_submissions/draft_email.json?id=${id}`);
  },
  sendChatMessage(data) {
    return this.execute('POST', `/v1/messages.json`, data);
  },
  getChatMessages(id) {
    return this.execute('GET', `/v1/messages.json?user_learn_obj_id=${id}`);
  },
  readMessage(data) {
    return this.execute('POST', `/v1/messages/mark_read.json`, data);
  },
  readSubmissionCount(data) {
    return this.execute('POST', `/v1/notifications/mark_read.json`, data);
  },
  getQuizQuestion2(id, data){
    return this.executeFormData('GET', `admin/v1/quiz_questions/${id}.json`, data);
  },
  findPlotPointEmail(id){
    return this.execute('POST', `/v1/user_learn_objs/${id}/user_email_variations.json`,);
  },
  sendExternalApiInfo(data){
    return this.execute('POST', `/v1/user_external_api_infos.json`, data);
  },
  sendExternalApiEvaluation(data){
    return this.execute('POST', `/v1/user_external_api_evaluations.json`, data);
  },
  getExternalApiEvaluation(id){
    return this.execute('GET', `/v1/user_external_api_evaluations/${id}.json`);
  },
  checkForLearnerId(fb){
    return this.execute('POST', `/v1/learner_registrations/pre_check_registration`, fb);
  },
  getLearnerRegistration(id){
    return this.execute('GET', `/v1/learner_registrations/${id}.json`)
  },
  getLearnerProfile(id) {
    return this.execute('GET', `/v1/profiles/${id}.json`);
  },
  updateProfile(id, data) {
    return this.execute('PATCH', `/v1/profiles/${id}.json`, {user : data});
  },
  removeProfileImage(id) {
    return this.execute('DELETE', `/v1/profiles/${id}/remove_profile_image.json`);
  },
  executeCode(data) {
    return this.execute('POST', `/v1/user_coding_iterations/run_code.json`, data);
  },
  submitCode(data) {
    return this.execute('POST', `/v1/user_coding_iterations.json`, data);
  },
  getLearnerCode(id){
    return this.execute('GET', `/v1/user_coding_evaluations/${id}/current_iteration.json?`);
  },
  codingIterationEvaluations(id) {
    return this.execute('GET', `/v1/user_coding_iterations/${id}.json`);
  },
  getConcepts(id){
    return this.execute('GET', `/v1/user_coding_evaluations/${id}/concepts.json?`);
  },
  getResourceUrl(id){
    return this.execute('GET', `/v1/global_resources/${id}.json?`);
  },
  getIntegrityAccept(id){
    return this.execute('GET', `/v1/user_sections/${id}.json`);
  },
  updateIntegrityAccept(id, form){
    return this.execute('PATCH', `/v1/user_sections/${id}.json`, form);
  },
  postContact(contact_data) {
    const data = {
      contact_data
    }
    return this.execute('POST', `/v1/contact_us.json`, data);
  },
  resetQuizs(id) {
    return this.execute('GET', `/v1/quiz_evaluations/${id}/reset_quiz.json`);
  },
  setHasReviewedFeedback(id, lo_id) {
    return this.execute('POST', `/v1/modules/${id}/module_details/set_has_reviewed_feedback.json?lo_id=${lo_id}`);
  },
  acceptSSOTerms(id) {
    return this.execute('POST', `/v1/modules/sso_accept_terms_conditions?learner_id=${id}`);
  }, 
  retryInteraction(module_id, ulo_id) {
    return this.execute('POST', `/v1/modules/${module_id}/module_details/reattempt_interaction.json?ulo_id=${ulo_id}`)
  },
  submitLlmChatQuestion(data) {
    return this.execute('POST', `admin/v1/llm_python/submit_llm_answer`, data);
  },
  submitLlmMessage(data) {
    return this.execute('POST', `v1/llm_learner_messages`, data);
  },
  getPrompts (id) {
    return this.execute('GET', `admin/v1/llm_chat_learn_objs/${id}/llm_prompts.json`);
  },
  resetLlmConversation(data) {
    return this.execute('POST', `admin/v1/reset_llm_conversation`, data);
  },
  getLlmChatAssessment(data) {
    return this.execute('GET', `v1/user_llm_chat_evaluations/${data}`);
  },
  getLlmDialogicAssessment(data) {
    return this.execute('GET', `v1/user_llm_dialogic_evaluations/${data}`);
  },
  getLlmEmailAssessment(data) {
    return this.execute('GET', `v1/user_llm_email_evaluations/${data}`);
  },
  submitLlmChatAssessment(data) {
    return this.execute('POST', `v1/user_llm_chat_evaluations`, data);
  },
  submitLlmDialogicAssessment(data) {
    return this.execute('POST', `v1/user_llm_dialogic_evaluations`, data);
  },
  submitLlmEmailAssessment(data) {
    return this.execute('POST', `v1/user_llm_email_evaluations`, data);
  },  
  getLlmChatHistory(data) {
    return this.execute('GET', `v1/llm_learner_messages?user_learn_obj_id=${data.user_learn_obj_id}`);
  },
  getFinalLlmDialogicFeedback(student_id) {
    return this.execute('POST', `admin/v1/get_final_dialogic_feedback`, student_id);
  },
  submitLlmEmailResponse(data) {
    return this.execute('POST', `admin/v1/submit_email_llm_answer`, data);
  },
  getDialogicFinalFeedbackMultiLo(data) {
    return this.execute('POST', `admin/v1/get_final_dialogic_feedback_multilo`, data);
  },
  setLlmIsComplete(data) {
    return this.execute('POST', `admin/v1/set_llm_is_complete`, data);
  },
  removeLlmChatAssessment(data) {
    return this.execute('DELETE', `v1/user_llm_chat_evaluations/${data}`);
  },
  removeLlmDialogicAssessment(data) {
    return this.execute('DELETE', `v1/user_llm_dialogic_evaluations/${data}`);
  },
  removeLlmEmailAssessment(data) {
    return this.execute('DELETE', `v1/user_llm_email_evaluations/${data}`);
  },
}
