<template>
  <div class="learn-steps">
    <h1 class="learn-title">{{ learnTitle }}</h1>
    <div v-if="stickyNote.length">
      <div v-for="(notes, index) in stickyNote" :key="index">
        <div class="learn-notes my-3" v-if="notes && notes.length">
          <p class="note-title">
            <img
              src="../assets/images/icon-yellownote.svg"
              alt="Yellow Note Icon"
            />Note
          </p>
          <p v-html="notes"></p>
        </div>
      </div>
    </div>

    <p class="learn-info" v-html="learnDesc"></p>
    <div class="writingAssessmentCount" v-if="this.writComprehensionDetected">
      <p>Coherence issues detected. See feedback for more information.</p>
    </div>
    <div v-if="emailLists.length > 0">
      <div class="learn-object-head" v-if="introEmailStatus">
        <p class="intro-subject">Subject: {{ introEmailSubject }}</p>
        <span v-if="toCharacters.length" class="first-row">
          <div class="learn-object-col email-lo-intro">
            <div class="learn-object-label">To</div>
            <div class="mail-subject-wrapper">
              <div
                class="cc_char_card_small"
                v-for="character in toCharacters"
                :key="character.id"
              >
                <div class="learn-object-user">
                  <img
                    :src="character.attributes.char_photo_url.thumbnail"
                    v-if="character.attributes.char_photo_url.thumbnail"
                    v-bind:alt="
                      character.attributes.char_first_name +
                      ' ' +
                      character.attributes.char_last_name +
                      ', ' +
                      character.attributes.world_role
                    "
                  />
                </div>
                <div class="learn-object-titles">
                  <h2>{{ character.attributes.char_full_name }}</h2>
                  <p>{{ character.attributes.world_role }}</p>
                </div>
              </div>
            </div>
          </div>
        </span>

        <span v-if="ccCharacters.length">
          <div class="learn-object-col cc-object-col email-lo-intro">
            <div class="learn-object-label">CC</div>
            <div class="learn-object-cc">
              <div
                class="cc_char_card_small"
                v-for="cc_character in ccCharacters"
                :key="cc_character.id"
              >
                <div class="learn-object-user">
                  <img
                    :src="cc_character.attributes.char_photo_url.thumbnail"
                    v-if="cc_character.attributes.char_photo_url.thumbnail"
                    v-bind:alt="
                      cc_character.attributes.char_first_name +
                      ' ' +
                      cc_character.attributes.char_last_name +
                      ', ' +
                      cc_character.attributes.world_role
                    "
                  />
                </div>
                <div class="learn-object-titles">
                  <h2>{{ cc_character.attributes.char_full_name }}</h2>
                  <p>{{ cc_character.attributes.world_role }}</p>
                </div>
              </div>
            </div>
          </div>
        </span>
        <div class="email-pdf-section">
          <p v-html="introEmailBody" class="email-subject"></p>
          <div class="learner-uploaded-data" v-if="introAttachment.length > 0">
            <div class="learning-data-list">
              <div class="learning-data-item">
                <div class="learning-data-icon">
                  <button
                    @click="
                      $modal.show('PdfModal', {
                        pdfUrl: introAttachment[0].attributes.resource_url,
                        fileName: introAttachment[0].attributes.file_name,
                      })
                    "
                    aria-label="Open PDF"
                  >
                    <img src="../assets/images/pdf-big-icon.png" alt="" />
                    <span class="visually-hidden">Open PDF</span>
                  </button>
                  <div class="text-detail">
                    <span class="text-right">{{
                      introAttachment[0].attributes.file_name
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="learn-reply-head subject-reply-head">
          <span>Reply</span>
        </div>
      </div>
      <div
        class="learn-object"
        v-for="(email, i) in emailLists"
        :key="email.id"
      >
        <div class="learn-object-forward">
          <div class="learn-object-tag email-lo-subject">
            Subject: {{ filterEmailIndex[i].sub }}
          </div>
          <div class="learn-object-head subject-object-head">
            <span v-if="toCharacters.length">
              <div class="learn-object-col email-lo-intro">
                <div class="learn-object-label">To</div>
                <div class="subject-object-wrap">
                  <div
                    class="cc_char_card_small"
                    v-for="character in toCharacters"
                    :key="character.id"
                  >
                    <div class="learn-object-user">
                      <img
                        :src="character.attributes.char_photo_url.thumbnail"
                        v-if="character.attributes.char_photo_url.thumbnail"
                        v-bind:alt="
                          character.attributes.char_first_name +
                          ' ' +
                          character.attributes.char_last_name +
                          ', ' +
                          character.attributes.world_role
                        "
                      />
                    </div>
                    <div class="learn-object-titles">
                      <h2>{{ character.attributes.char_full_name }}</h2>
                      <p>{{ character.attributes.world_role }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <span v-if="ccCharacters.length">
              <div class="learn-object-col email-lo-intro">
                <div class="learn-object-label">CC</div>
                <div class="subject-object-wrap">
                  <div
                    class="cc_char_card_small"
                    v-for="cc_character in ccCharacters"
                    :key="cc_character.id"
                  >
                    <div class="learn-object-user">
                      <img
                        :src="cc_character.attributes.char_photo_url.thumbnail"
                        v-if="cc_character.attributes.char_photo_url.thumbnail"
                        v-bind:alt="
                          cc_character.attributes.char_first_name +
                          ' ' +
                          cc_character.attributes.char_last_name +
                          ', ' +
                          cc_character.attributes.world_role
                        "
                      />
                    </div>
                    <div class="learn-object-titles">
                      <h2>
                        {{ cc_character.attributes.char_full_name }}
                      </h2>
                      <p>{{ cc_character.attributes.world_role }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </span>

            <span>
              <div class="learn-object-col email-lo-intro subject-from-col">
                <div class="learn-object-label">From</div>
                <div class="intro-mail-wrap">
                  <div class="cc_char_card_small">
                    <div class="learn-object-user">
                      <img
                        :src="user.attributes.profile_url"
                        v-if="user.attributes.profile_url"
                        alt=""
                      />
                    </div>
                    <div class="learn-object-titles">
                      <h2>{{ user.attributes.full_name }}</h2>
                    </div>
                  </div>
                </div>
              </div>
            </span>
          </div>

          <div class="learn-object-paragraph">
            <div class="learn-object-desc writ-comprehension">
              
              <p v-if="!writComprehensionDetected" v-html="filterEmailIndex[i].msg"></p>

            </div>
          </div>
        </div>
        <div
          class="learn-object-reply"
          v-for="item in filterEmailIndex[i].arr"
          :key="item.id"
        >
          <div class="learn-reply-head">
            <span
              >Reply from
              {{ item.attributes.character.attributes.char_full_name }}</span
            >
          </div>
          <div class="learn-object-head">
            <div class="learn-object-col">
              <div class="learn-object-label">To</div>
              <div class="learn-object-user">
                <img
                  :src="user.attributes.profile_url"
                  v-if="user.attributes.profile_url"
                  alt=""
                />
              </div>
              <div class="learn-object-titles">
                <h2>{{ user.attributes.full_name }}</h2>
              </div>
            </div>
          </div>

          <div class="learn-object-head">
            <div class="learn-object-col">
              <div class="learn-object-label">From</div>
              <div class="learn-object-user">
                <img
                  :src="item.attributes.character.attributes.char_photo_url.thumbnail"
                  v-if="item.attributes.character.attributes.char_photo_url.thumbnail"
                  v-bind:alt="
                    item.attributes.character.attributes.char_first_name +
                    ' ' +
                    item.attributes.character.attributes.char_last_name +
                    ', ' +
                    item.attributes.character.attributes.world_role
                  "
                />
              </div>
              <div class="learn-object-titles">
                <h2>{{ item.attributes.character.attributes.char_full_name }}</h2>
                <p>{{ item.attributes.character.attributes.world_role }}</p>
              </div>
            </div>
          </div>

          <div class="learn-object-paragraph">
            <div class="learn-object-desc">
              <div class="emailBody" v-html="item.attributes.response"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="learn-object"
      v-if="emailLists.length == 0 || (nextIteration && !ootoResponseStatus)"
    >
      <div class="learn-object-editable">
        <div
          class="learn-object-head in-progress-wrapper"
          v-if="introEmailStatus"
        >
          <h2 class="subject-title">Subject: {{ introEmailSubject }}</h2>
          <span v-if="toCharacters.length">
            <div class="learn-object-col email-lo-intro">
              <div class="learn-object-label">To</div>
              <div class="card-small-wrap intro-small-wrap">
                <div
                  class="cc_char_card_small"
                  v-for="character in toCharacters"
                  :key="character.id"
                >
                  <div class="learn-object-user">
                    <img
                      :src="character.attributes.char_photo_url.thumbnail"
                      v-if="character.attributes.char_photo_url.thumbnail"
                      v-bind:alt="
                        character.attributes.char_first_name +
                        ' ' +
                        character.attributes.char_last_name +
                        ', ' +
                        character.attributes.world_role
                      "
                    />
                  </div>
                  <div class="learn-object-titles">
                    <h2>{{ character.attributes.char_full_name }}</h2>
                    <p>{{ character.attributes.world_role }}</p>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <span v-if="ccCharacters.length">
            <div class="learn-object-col email-lo-intro">
              <div class="learn-object-label">CC</div>
              <div class="email-card-small-wrapper">
                <div
                  class="cc_char_card_small"
                  v-for="cc_character in ccCharacters"
                  :key="cc_character.id"
                >
                  <div class="learn-object-user">
                    <img
                      :src="cc_character.attributes.char_photo_url.thumbnail"
                      v-if="cc_character.attributes.char_photo_url.thumbnail"
                      v-bind:alt="
                        cc_character.attributes.char_first_name +
                        ' ' +
                        cc_character.attributes.char_last_name +
                        ', ' +
                        cc_character.attributes.world_role
                      "
                    />
                  </div>
                  <div class="learn-object-titles">
                    <h2>{{ cc_character.attributes.char_full_name }}</h2>
                    <p>{{ cc_character.attributes.world_role }}</p>
                  </div>
                </div>
              </div>
            </div>
          </span>
          <div class="email-pdf-section">
            <p v-html="introEmailBody" class="intro-text"></p>
            <div
              class="learner-uploaded-data"
              v-if="introAttachment.length > 0"
            >
              <div class="learning-data-list">
                <div class="learning-data-item">
                  <div class="learning-data-icon">
                    <button
                      @click="
                        $modal.show('PdfModal', {
                          pdfUrl: introAttachment[0].attributes.resource_url,
                          fileName: introAttachment[0].attributes.file_name,
                        })
                      "
                    >
                      <img src="../assets/images/pdf-big-icon.png" alt="" />
                      <span class="visually-hidden">Open PDF</span>
                    </button>
                    <div class="text-detail">
                      <span class="text-right">{{
                        introAttachment[0].attributes.file_name
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="learn-reply-head">
            <span>Reply</span>
          </div>
        </div>
        <div class="learn-object-head">
          <span v-if="toCharacters.length">
            <div class="learn-object-col email-lo-intro">
              <div class="learn-object-label">To</div>
              <div class="email-reply-wrap">
                <div
                  class="cc_char_card_small"
                  v-for="character in toCharacters"
                  :key="character.id"
                >
                  <div class="learn-object-user">
                    <img
                      :src="character.attributes.char_photo_url.thumbnail"
                      v-if="character.attributes.char_photo_url.thumbnail"
                      v-bind:alt="
                        character.attributes.char_first_name +
                        ' ' +
                        character.attributes.char_last_name +
                        ', ' +
                        character.attributes.world_role
                      "
                    />
                  </div>
                  <div class="learn-object-titles">
                    <h2>{{ character.attributes.char_full_name }}</h2>
                    <p>{{ character.attributes.world_role }}</p>
                  </div>
                </div>
              </div>
            </div>
          </span>

          <span v-if="ccCharacters.length">
            <div class="learn-object-col email-lo-intro">
              <div class="learn-object-label">CC</div>
              <div class="email-reply-wrap">
                <div
                  class="cc_char_card_small"
                  v-for="cc_character in ccCharacters"
                  :key="cc_character.id"
                >
                  <div class="learn-object-user">
                    <img
                      :src="cc_character.attributes.char_photo_url.thumbnail"
                      v-if="cc_character.attributes.char_photo_url.thumbnail"
                      v-bind:alt="
                        cc_character.attributes.char_first_name +
                        ' ' +
                        cc_character.attributes.char_last_name +
                        ', ' +
                        cc_character.attributes.world_role
                      "
                    />
                  </div>
                  <div class="learn-object-titles">
                    <h2>{{ cc_character.attributes.char_full_name }}</h2>
                    <p>{{ cc_character.attributes.world_role }}</p>
                  </div>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div class="learn-object-editor">
          <div class="form-group" v-if="emailLists.length == 0">
            <input
              type="text"
              class="form-control"
              placeholder="Add Subject"
              aria-label="Add Subject"
              v-model="emailSubject"
              @input="autosaveEmail()"
            />
          </div>
          <div class="form-group">
            <vue-editor
              class="form-control"
              :editorToolbar="simpleToolbar"
              v-model="emailDesc"
              @input="
                emailBody();
                autosaveEmail();
              "
            ></vue-editor>
          </div>
          <!-- minimun characters   -->
          <div v-if="!emailMinReqSatisfied" class="invalid text-right w-100 mr-2">
            <span>
              Minimum Characters {{emailLength}}/{{emailMinReq}}
            </span>
          </div>
          <!-- minimun characters   -->
          <div class="learn-object-send">
            <button
              :disabled="disableNextBtn"
              @click="sendEmail"
              class="btn btn-ametros"
              aria-label="Send Email"
            >
              {{ emailSendBtnText }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="learn-object yellow-note" v-if="ootoResponseStatus">
      <p>{{ this.ootoNotice }}</p>
      <p><br /></p>
      <p>In the meantime please review the content below.</p>
    </div>
    <div class="finished-area">
      <div
        class="learn-btn left-right"
        v-if="!nextIteration && !ootoResponseStatus && emailLists.length != 0"
      >
        <button
          @click="resetEmailInteraction"
          class="btn secondary"
          v-if="user_roles.includes('testing-internal')"
        >
          Reset Interaction
        </button>
        <button @click="showEmailFeedBack" class="btn secondary">
          See Feedback
          <em class="icon-arrow-right"></em>
        </button>
      </div>

      <div class="learn-btn left-right" v-if="ootoResponseStatus">
        <button
          @click="resetEmailInteraction"
          class="btn secondary"
          v-if="user_roles.includes('testing-internal')"
        >
          Reset Interaction
        </button>
      </div>
      <div class="interstitial-footer" v-if="showInterstitial">
        <div
          class="interstitial-footer-item"
          v-for="item of interstitialContents"
          :key="item.key"
        >
          <button
            @click="
              selectedInterstitial(item);
              show('gatheringInformation');
            "
            >{{ item.attributes.title
            }}<span
              v-if="item.attributes.content_details.type == 'text_learn_obj'"
              >Text</span
            >
            <span v-if="item.attributes.content_details.type == 'video_learn_obj'"
              >Video</span
            >
            <span v-if="item.attributes.content_details.type == 'slide_learn_obj'"
              >Slide</span
            >
            <span v-if="item.attributes.content_details.type == 'file_learn_obj'"
              >File</span
            >
          </button>
        </div>
      </div>
      <lo-buttons
        v-if="!ootoResponseStatus && emailLists.length != 0 && !nextIteration"
        :nextStatus="nextStatus"
        :nextMethod="next"
        text="See Final Feedback"
        :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
      ></lo-buttons>
    </div>
    <email-no-plot-point-feedback-modal></email-no-plot-point-feedback-modal>
    <feedback-info-modal></feedback-info-modal>
  </div>
</template>
<script>
import { utilFunctionService } from "@/utils/utils.service";
import { VueEditor } from "vue2-editor";

import api from "../services/api";
import debounce from "lodash/debounce";
import EmailNoPlotPointFeedbackModal from "./emailNoPlotPointFeedbackModal.vue";
import FeedbackInfoModal from "./feedbackInfoModal.vue";
import loButtons from "./loButtons.vue";

export default {
  name: "EmailNoPlotPoint",
  props: ["active_data", "nextStatus", "module_id"],
  components: {
    VueEditor,
    EmailNoPlotPointFeedbackModal,
    FeedbackInfoModal,
    loButtons
  },
  data() {
    return {
      emailLength: 0,
      emailMinReq: 75,
      emailMinReqSatisfied: false,
      iteration_number: 0,
      learnTitle: this.active_data.attributes.learning_object.attributes.name,
      learnDesc:
        this.active_data.attributes.learning_object.attributes.description,
      stickyNote: [],
      introEmailStatus: false,
      toCharacters: "",
      ccCharacters: "",
      filterEmailIndex: {},
      nextIteration: false,
      ootoResponseStatus: false,
      user_roles: utilFunctionService.getUserRoles(),
      showInterstitial: false,
      emailSubject: "",
      fullToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      simpleToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      emailDesc: "",
      disableNextBtn: true,
      emailSendBtnText: "Send",
      emailLists: [],
      emailfeedbackResponse: "",
      hasReviewedFeedback: false,
      infoModalValues: {},
      writComprehensionDetected: false,
      paragraphs: [
        {
          id: 1,
          coherent: false,
          paragraph_coherence: 50,
          sentences: [
            {
              id: 1,
              coherent: false,
              assessed_sentence:
                "Here is some chunky text to confirm that it is being passed in nicely.",
              sentence_coherence: 50,
            },
            {
              id: 2,
              coherent: true,
              assessed_sentence:
                "Nunc a nunc neque. Phasellus ullamcorper luctus justo, id blandit mauris pellentesque in.",
              sentence_coherence: 50,
            },
          ],
        },
        {
          id: 2,
          coherent: false,
          paragraph_coherence: 50,
          sentences: [
            {
              id: 1,
              coherent: true,
              assessed_sentence:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam id neque sed tellus ornare congue molestie vestibulum elit.",
              sentence_coherence: 50,
            },
            {
              id: 2,
              coherent: true,
              assessed_sentence:
                "Nunc a nunc neque. Phasellus ullamcorper luctus justo, id blandit mauris pellentesque in.",
              sentence_coherence: 50,
            },
          ],
        },
      ],
      course_lo_reattempts_enabled: false,
      lo_reattempts_enabled: false,
      lo_reattempts_max: '',
      lo_reattempt_number: '',
      lo_reattempts_remaining: '',
      email_sent: false,
      showCertificateOfCompletion: false
    };
  },
  created() {
    this.toCharacters =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.to_characters;
    this.ccCharacters =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.cc_characters;
    this.interstitialContents =
      this.active_data.attributes.learning_object.attributes.card_detail.attributes.interstitial_contents;
    this.selectedInterstitialContent = this.interstitialContents[0];
    this.interactiveEvaluationId = this.active_data.attributes.current_evaluation_id;
    this.user = this.active_data.attributes.user_section.attributes.user;
    this.hasReviewedFeedback =
      this.active_data.attributes.has_reviewed_feedback;
    if (this.learningObjectType != "plot_point") {
      this.getEmailLists();
      this.getDraftEmail();
    }
  },
  methods: {
    // showCertificationOfCompletion() {
    //   if (this.hasReviewedFeedback) {
    //     this.$parent.showCertificationOfCompletion();
    //   } else {
    //     utilFunctionService.showerr(
    //       "Please review your feedback before proceeding to the next task"
    //     );
    //   }
    // },
    next() {
      if (this.hasReviewedFeedback) {
        this.$parent.next();
      } else {
        utilFunctionService.showerr(
          "Please review your feedback before proceeding to the next task"
        );
      }
    },
    getEmailBody() {
      if (!this.active_data.attributes.email_variation) {
        api.findPlotPointEmail(this.active_data.id).then((res) => {
          let emailBody = res.data.email_body;
          this.emailBody = emailBody.replaceAll(
            "[learner-name]",
            this.user.attributes.first_name
          );
        });
      } else {
        let emailBody = this.active_data.attributes.email_variation;
        this.emailBody = emailBody.replaceAll(
          "[learner-name]",
          this.user.attributes.first_name
        );
      }
    },
    resetEmailInteraction() {
      api
        .resetEmailInteraction(this.active_data.attributes.current_evaluation_id)
        .then(() => {
          this.$router.push({path: `/learning/${this.module_id}/${this.active_data.id}`})
          this.$router.go();
        })
        .catch(() => {});
    },
    showEmailFeedBack() {
      api.emailFeedbackResponse(this.interactiveEvaluationId).then((res) => {
        if (!this.hasReviewedFeedback) {
          api
            .setHasReviewedFeedback(this.module_id, this.active_data.id)
            .then(() => {
              this.hasReviewedFeedback = true;
            })
            .catch(() => {
              console.log("");
            });
        }
        console.log(res);
        this.emailfeedbackResponse = res.data.data;
        let overallAssmntItem =
          res &&
          res.data &&
          res.data.data &&
          res.data.data.attributes &&
          res.data.data.attributes.overall_assmnt_item
            ? res.data.data.attributes.overall_assmnt_item.attributes
            : "";
        let hideAssessmentLabel =
          res &&
          res.data &&
          res.data.data &&
          res.data.data.attributes &&
          res.data.data.attributes.hide_assessment_label;
        let course_lo_reattempts_disabled = res.data.data.attributes.course_lo_reattempts_disabled;
        let lo_reattempts_enabled = res.data.data.attributes.lo_reattempts_enabled;
        let lo_reattempts_max = res.data.data.attributes.lo_reattempts_max;
        let lo_reattempt_number = res.data.data.attributes.lo_reattempt_number;
        this.$modal.show("emailNoPlotPointFeedback", {
          nextStatus: this.nextStatus,
          emailFeedbackResponse: this.emailfeedbackResponse,
          overallAssmntItem: overallAssmntItem,
          hideAssessmentLabel: hideAssessmentLabel,
          course_lo_reattempts_disabled: course_lo_reattempts_disabled,
          lo_reattempts_enabled: lo_reattempts_enabled,
          lo_reattempts_max: lo_reattempts_max,
          lo_reattempt_number: lo_reattempt_number,
          module_id: this.module_id,
          active_data_id: this.active_data.id
        });
      });
    },
    sendEmail() {
      const fb = new FormData();
      fb.append("user_email_iteration[email]", this.emailDesc);
      fb.append("email_subject", this.emailSubject);
      this.disableNextBtn = true;
      this.emailSendBtnText = "Sending...";
      this.email_sent = true;
      utilFunctionService.showLoader();
      api.sendEmailResponse(this.interactiveEvaluationId, fb).then(() => {
        this.emailDesc = "";
        this.getEmailLists();
        //utilFunctionService.showSuccess("Email sent successfully")
        setTimeout(() => {
          utilFunctionService.hideLoader();
          this.emailSendBtnText = "Send";
          this.email_sent = false;
        }, 2100);
      });
    },
    getEmailLists() {
      api
        .getEmailList(this.interactiveEvaluationId)
        .then((res) => {
          this.ootoCharacters = [];
          this.emailLists = res.data.data.reverse();
          if (this.emailLists.length > 0) {
            this.nextIteration =
              this.emailLists[
                this.emailLists.length - 1
              ].attributes.next_iteration_required;
          } else {
            this.nextIteration = false;
          }
          this.emailLists.forEach((z, i) => {
            let filterEmailList = [];
            z.attributes.responses.forEach((x) => {
              let a = z.attributes.responses.filter((o) => {
                if (
                  x.attributes.character.attributes.character_id ==
                  o.attributes.character.attributes.character_id
                ) {
                  return o;
                }
              });

              if (a.length > 1) {
                let q = filterEmailList.find(
                  (x) =>
                    a[0].attributes.character.attributes.character_id ==
                    x.attributes.character.attributes.character_id
                );
                if (q) {
                  return;
                }
                let ootoRes = a.find(
                  (e) => e.attributes.is_ooto_response == true
                );
                filterEmailList.push(ootoRes);
                let qaFixedRes = a.find((e) => e.attributes.qa_fixed == true);
                if (qaFixedRes) {
                  filterEmailList.push(qaFixedRes);
                }
                if (ootoRes && !qaFixedRes) {
                  this.ootoResponseStatus = true;
                  this.ootoCharacters.push(
                    a[0].attributes.character.attributes.char_first_name
                  );
                }
                if (ootoRes) {
                  this.showInterstitial = true;
                }
              } else {
                if (a[0].attributes.is_ooto_response) {
                  this.ootoResponseStatus = true;
                  this.showInterstitial = true;
                  this.ootoCharacters.push(
                    a[0].attributes.character.attributes.char_first_name
                  );
                }
                filterEmailList.push(a[0]);
              }
            });
            this.filterEmailIndex[i] = {
              arr: filterEmailList,
              sub: z.attributes.email_subject,
              msg: z.attributes.email,
            };
          });
          // This is used to inject the writing comprehension content after
          // the v-for has rendered
          if (this.writComprehensionDetected) {
            this.$nextTick(() => {
              const writComprehension = document.querySelector(".writ-comprehension");
              this.paragraphs.forEach((paragraph) => {
                let para = document.createElement("p");
                paragraph.sentences.forEach((sentence) => {
                  if (sentence.coherent) {
                    let node = document.createTextNode(sentence.assessed_sentence);
                    para.appendChild(node);
                  } else {
                    let spanNode = document.createElement("span");
                    spanNode.setAttribute("class", "highlight");
                    let node = document.createTextNode(sentence.assessed_sentence);
                    spanNode.appendChild(node);
                    para.appendChild(spanNode);
                  }
                });
                writComprehension.appendChild(para);
              });
            });
          }
          if (this.ootoResponseStatus == true) {
            this.setOotoNotice();
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    getDraftEmail() {
      api.getDraftEmail(this.interactiveEvaluationId).then((res) => {
        if (res.data && res.data.draft_state) {
          this.emailDesc = res.data.email;
          this.emailSubject = res.data.subject;
        }
      });
    },
    autosaveEmail: debounce(function () {
      if (this.email_sent === false) {
        this.saveDraft();
      }
    }, 2000),

    emailBody() {
      if (this.emailDesc.length - 7 < 75) {
        if (this.emailDesc.length === 0) {
          this.disableNextBtn = true;
          this.emailMinReqSatisfied = false;
          this.emailLength = 0;
        } else {
          this.disableNextBtn = true;
          this.emailMinReqSatisfied = false;
          this.emailLength = this.emailDesc.length - 7;
        }
      } else {
        this.disableNextBtn = false;
        this.emailMinReqSatisfied = true;
        this.emailLength = this.emailDesc.length;
      }
    },
    saveDraft() {
      const fb = new FormData();
      fb.append("user_email_iteration[email]", this.emailDesc);
      fb.append("user_email_iteration[is_draft]", true);
      fb.append("email_subject", this.emailSubject);
      if (this.emailDesc) {
        api.sendEmailResponse(this.interactiveEvaluationId, fb);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.emailBody {
  :deep( p ) {
    margin: 0 !important;
  }
}
.cc-label {
  padding-left: 45px;
  font-size: 13px;
  color: #606060;
  display: flex;
}
.learn-object-mail {
  border-top: 1px solid #ccc;
  padding-top: 2em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 2em;
}
.cc-nowrap {
  padding: 1em 0 0 1em;
}
.writingAssessmentCount {
  margin: 16px 0;
  max-width: 425px;
  p {
    background: yellow;
  }
}

</style>