<template>
  <div>
    <div class="learn-header" id="back-to-dashboard">
      <div class="learn-header-info">
        <router-link
          to="/active"
          class="learn-header-icon"
          aria-label="Back to Dashboard"
        >
          <em class="icon-back"></em>
        </router-link>
        
      </div>
    </div>
    <main id="register" class="register-container">
      <div id="logo-box">
        <router-link to="/" tabindex="0">
          <img
            src="../assets/images/ametros-learning-logo.svg"
            alt="Ametros Learning Logo"
            class="register-logo"
            aria-label="Ametros Learning Logo"
          />
        </router-link>
      </div>
  
      <payment-form
        v-if="showPaymentForm"
        :course_name="this.course_name"
        :price="this.price"
        :tax_rate="this.tax_rate"
        :taxable="this.taxable"
        :final_price="this.final_price"
        :loggedIn="this.loggedIn"
        :learner_registration_id="this.learner_registration_id"
        :currency="this.currency"
      >
      </payment-form>
  
      <div >
        <div
          id="register-login"
          class="register-login-container"
        >
          <div class="login-form">
            <h1>Add Module</h1>
            <p>
              Enter a registration code below to add a new module to your Ametros
              account.
            </p>
            <br />
            <ValidationObserver ref="form">
              <form class="w-100">
                <div class="form-group">
                  <ValidationProvider
                    name="Registration Code"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input
                      class="form-control"
                      type="text"
                      aria-label="Enter registration code"
                      placeholder="Enter registration code"
                      v-model="input.reg_code"
                      @input="getRegCode(input.reg_code)"
                      :class="[isActive && errors[0] ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[
                        isActive ? { display: 'block' } : { display: 'none' },
                      ]"
                      v-if="errors[0]"
                      >{{ errors[0].replace("The ", "") }}</span
                    >
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[
                        isActive ? { display: 'block' } : { display: 'none' },
                      ]"
                      v-if="RegCodeError && showError"
                      >Incorrect registration code</span
                    >
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[
                        isActive ? { display: 'block' } : { display: 'none' },
                      ]"
                      v-if="RegCodeUsedError && showError"
                      >This single use registration code has already been used and is no longer valid.</span
                    >
                  </ValidationProvider>
                </div>
  
                <div class="form-group" v-if="displayStudentIdField">
                  <ValidationProvider
                    name="Student ID"
                    rules="required"
                    v-slot="{ errors }"
                    v-if="!fromPearson"
                  >
                    <input
                      class="form-control"
                      type="text"
                      aria-label="Enter student ID"
                      placeholder="Enter student ID"
                      v-model="input.student_id"
                      @input="input.student_id"
                      :class="[isActive && errors[0] ? 'invalid' : '']"
                    />
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[
                        isActive ? { display: 'block' } : { display: 'none' },
                      ]"
                      v-if="errors[0]"
                      >{{ errors[0].replace("The ", "") }}</span
                    >
                    <span
                      :class="[isActive ? 'invalid' : '']"
                      :style="[
                        isActive ? { display: 'block' } : { display: 'none' },
                      ]"
                      v-if="studentIderr && showError"
                      >Incorrect student ID</span
                    >
                  </ValidationProvider>
                </div>
  
                <div class="text-end">
                  <button
                    type="button"
                    class="btn btn-ametros"
                    @click.once="loggedinRegister()"
                  >
                    Add
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <terms-conditions></terms-conditions>
    </main>
  </div>
</template>

<script>
import api from "../services/api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { utilFunctionService } from "@/utils/utils.service";
import { commonConfig } from "@/utils/commonConfig";
import PaymentForm from "@/components/PaymentForm.vue";
import termsConditions from "@/components/termsConditions.vue";
import { mapGetters } from "vuex";

export default {
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title;
      },
    },
  },
  name: "Register-component",
  components: {
    ValidationProvider,
    ValidationObserver,
    PaymentForm,
    termsConditions,
  },
  props: ["regcode", "fromPearson"],
  computed: {
    ...mapGetters("user", {
      userDetails: "getUserDetails",
    }),
  },
  data() {
    return {
      input: {
        reg_code: "",
        student_id: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirm: "",
      },
      payment_form: {
        card_number: "",
        expiration_month: "",
        expiration_year: "",
        verification_code: "",
      },
      title: "Register",
      url: process.env.VUE_APP_URL,
      RegCodeError: false,
      studentIderr: false,
      acceptTerms: false,
      showTermsConditionsError: false,
      isActive: "",
      showError: false,
      passwordMismatch: false,
      showNewRegister: false,
      showExistingRegister: false,
      hideUserChoice: false,
      showPaymentForm: false,
      loggedIn: false,
      currency: "",
      price: "",
      tax_rate: "",
      taxable: true,
      final_price: "",
      order_id: "",
      end_user_paid: false,
      account_exists: false,
      payment_attempt: true,
      course_name: "",
      purchase_result: "",
      showPaymentError: false,
      learner_registration_id: "",
      learnerRegData: {},
      payment_type: "",
      no_charge: false,
      courseData: {},
      customer_course_id: null,
      paymentSuccess: false,
      loggedinPaymentSuccess: false,
      loggedinShowPaymentError: false,
      hideAlreadyHaveAccount: false,
      loggedinShowPaymentForm: false,
      emailError: false,
      displayStudentIdField: false,
      RegCodeUsedError: false
    };
  },
  created() {
    const userDetails = localStorage.getItem("vuex");
    if (userDetails) {
      this.loggedIn = true;
      this.email = userDetails.user.user.attributes.email;
      this.first_name = userDetails.user.user.attributes.first_name;
      this.last_name = userDetails.user.user.attributes.last_name;
    }
    
  },
  methods: {
    register() {
      utilFunctionService.showLoader();
      this.$refs.form.validate().then((success) => {
        this.isActive = true; 
        if (!success || this.passwordMismatch) {
          utilFunctionService.hideLoader();
          return;
        } else {
          if (this.acceptTerms === false) {
            utilFunctionService.hideLoader();
            this.showTermsConditionsError = true;
          } else {
            const form = {
              email: this.input.email,
              password: this.input.password,
              password_confirm: this.input.password_confirm,
              first_name: this.input.first_name,
              last_name: this.input.last_name,
              reg_code: this.fromPearson ? this.regcode : this.input.reg_code,
              id_for_learner_customer: this.input.student_id,
              existing_account: this.account_exists,
            };
            api
              .postRegister(form)
              .then((res) => {
                if (res && res.data) {
                  if (res.data.data.attributes.customer_course_id == null) {
                    utilFunctionService.hideLoader();
                    utilFunctionService.showSuccess(
                      commonConfig.appMessages.registerSuccess
                    );
                    this.$router.replace({ name: "login" });
                  } else {
                    utilFunctionService.hideLoader();
                    this.learnerRegData = res.data.data;
                    this.customer_course_id =
                      res.data.data.attributes.customer_course.id;
                    this.courseData =
                      res.data.data.attributes.customer_course.attributes;
                    this.learner_registration_id = this.learnerRegData.id;
                    this.account_exists =
                      this.learnerRegData.attributes.existing_account;
                    this.course_name =
                      this.learnerRegData.attributes.course_name;
                    this.payment_type =
                      this.learnerRegData.attributes.payment_type;
                    this.price = parseFloat(this.courseData.price);
                    this.tax_rate = parseFloat(this.courseData.tax_rate);

                    this.no_charge = this.courseData.no_charge;

                    this.currency = this.courseData.currency;
                    this.final_price = this.price + this.price * this.tax_rate;
                    if (this.payment_type == "end_user" && this.no_charge == false) {
                      this.showPaymentForm = true;
                      this.showNewRegister = false;
                    } else {
                      utilFunctionService.showSuccess(
                        commonConfig.appMessages.registerSuccess
                      );
                      this.$router.replace({ name: "login" });
                    }
                  }
                }
              })
              .catch((error) => {
                utilFunctionService.hideLoader();
                if (error.response && error.response.data) {
                  let res = error.response.data;
                  this.showError = true;
                  this.RegCodeError = !res.valid_code;
                  this.emailError = res.user_exists;
                  this.studentIderr = !res.valid_id;
                  this.RegCodeUsedError = res.reg_code_used;
                }
              });
          }
        }
      });
    },

    loggedinRegister() {
      this.isActive = true;
      const form = {
        email: this.userDetails.attributes.email,
        password: "placeholder",
        password_confirm: "placholder",
        first_name: this.userDetails.attributes.first_name,
        last_name: this.userDetails.attributes.last_name,
        reg_code: this.input.reg_code,
        existing_account: true,
      };
      if (this.input.reg_code != null) {
        api
          .postRegister(form)
          .then((res) => {
            if (res && res.data) {
              if (res.data.data.attributes.customer_course_id == null) {
                utilFunctionService.showSuccess(
                  commonConfig.appMessages.RegisterSuccess
                );
                this.$router.replace({ name: "active" });
              } else {
                this.learnerRegData = res.data.data;
                this.customer_course_id =
                  res.data.data.attributes.customer_course.id;
                this.courseData =
                  res.data.data.attributes.customer_course.attributes;
                this.learner_registration_id = this.learnerRegData.id;
                this.account_exists =
                  this.learnerRegData.attributes.existing_account;
                this.course_name =
                  this.learnerRegData.attributes.course_name;
                this.payment_type =
                  this.learnerRegData.attributes.payment_type;
                this.price = parseFloat(this.courseData.price);
                this.tax_rate = parseFloat(this.courseData.tax_rate);

                this.currency = this.courseData.currency;
                this.final_price = this.price + this.price * this.tax_rate;
                if (this.payment_type == "end_user") {
                  this.showPaymentForm = true;
                  this.hideAlreadyHaveAccount = true;
                } else {
                  utilFunctionService.showSuccess(
                    commonConfig.appMessages.registerSuccess
                  );
                  this.$router.replace({ name: "dashboard" });
                }
              }
            }
          })
          .catch((error) => {
            if (error.response && error.response.data) {
              let res = error.response.data;
              this.showError = true;
              this.RegCodeError = !res.valid_code;
              this.studentIderr = !res.valid_id;
              this.RegCodeUsedError = res.reg_code_used;
              
            }
          });
      }
    },
    showTermsConditions() {
      this.$modal.show("TermsAndConditions");
    },
    setTermsCheckboxValue() {
      if (this.acceptTerms) {
        this.acceptTerms = false;
      } else {
        this.acceptTerms = true;
      }
    },
    getCheckboxValue() {
      if (this.acceptTerms) {
        this.showTermsConditionsError = false;
      } else {
        this.showTermsConditionsError = true;
      }
    },
    getRegCode() {
      this.showError = false;
    },
    checkForLearnerId() {
      if (this.input.reg_code != null) {
        const fb = { reg_code: this.input.reg_code };
        api.checkForLearnerId(fb).then((res) => {
          this.displayStudentIdField = res.data.student_id_required;
          
        });
      }
    },
    confirmPassword() {
      if (this.input.password !== this.input.password_confirm) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
#logo-box {
  display: flex;
}
#user-choice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  margin-bottom: 1em;
  margin-top: 2em;
  button {
    width: 100%;
    margin-bottom: 32px;
  }
}
#back-to-dashboard {
  padding-left: 2em;
}
.credit-card-header {
  margin-bottom: 1em;
  margin-top: 1em;
}

.credit-card-form {
  margin-top: 2em;
}

.payment-problem {
  padding: 1em;
  background-color: rgb(244, 245, 208);
}

.register-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  a {
    text-decoration: underline $blue-color solid 1px !important;
  }
  .register-logo {
    width: 180px;
    margin: 0 auto;
    padding-bottom: 2em;
  }
  .register-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
  .left-align {
    text-align: left;
  }
  h1 {
    @include font-bold;
    //text-transform: uppercase;
    font-size: 28px;
    margin: 0 0 40px;
    text-align: left;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .modal {
    max-height: 750px;
    overflow-y: auto;
  }
}
</style>