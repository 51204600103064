import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/login'
import LtiLogout from '../views/lti_logout'
import LtiInfo from '../views/lti_info'
import Register from '../views/register'
import RegisterLoggedIn from '../views/registerLoggedIn'
import PayTest from '../views/payment_test'
import PayTestSuccess from '../views/payment_test_success'
import ForgotPassword from '../views/forgotPassword'
import ResetPassword from '../views/resetPassword'
import editProfile from '../views/editProfile'
import auth from './middleware/auth'
import isLoggedin from './middleware/isLoggedIn'
import middlewarePipeline from './middlewarePipeline'
import learning from '../views/learning'
import dashboard from '../views/dashboard'
import listData from '../views/listData'
import PearsonRegister from '../views/pearsonRegister'
import ContactUs from '../views/contactUs'
import LoginSAML from '../views/loginSaml'

Vue.use(Router)

const router = new Router({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                middleware: [
                    isLoggedin
                ]
            },
            alias: '/'
        },
        {
          path: '/login-saml',
          name: 'login-saml',
          component: LoginSAML,
          meta: {
              middleware: [
                  isLoggedin
              ]
          },
          alias: '/'
      },
        {
          path: '/lti-logout',
          name: 'lti-logout',
          component: LtiLogout
        },
        {
          path: '/lti-info',
          name: 'lti-info',
          component: LtiInfo
        },
        {
            path: '/paytest-success',
            name: 'paytest-success',
            component: PayTestSuccess
        },
        {
            path: '/payment-test',
            name: 'payment-test',
            component: PayTest
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: ForgotPassword
        },
        {
            path: '/password_reset/:token',
            name: 'password_reset',
            component: ResetPassword
        },
        {
            path: '/register',
            name: 'register',
            component: Register
        },
        {
          path: '/register-logged-in',
          name: 'register-logged-in',
          component: RegisterLoggedIn
      },
        {
            path: '/contact',
            name: 'contact',
            component: ContactUs,
            meta: {
                middleware: [
                    auth
                ]
            },
            alias: '/'
        },
        {
          path: "/profile",
          name: "edit profile",
          component: editProfile,
          meta: {
            middleware: [
              auth
            ]
          }
        },
        {
            path: '/register/etext-access/:regcode',
            name: 'pearson-register',
            component: PearsonRegister
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: dashboard,
            meta: {
                middleware: [
                    auth
                ]
            }
        },
        {
            path: "/active",
            name: "active",
            component: listData,
            meta: {
                middleware: [
                    auth
                ]
            }
        },
        {
            path: "/completed",
            name: "completed",
            component: listData,
            meta: {
                middleware: [
                    auth
                ]
            }
        },
        {
            path: "/expired",
            name: "expired",
            component: listData,
            meta: {
                middleware: [
                    auth
                ],
                reload: true
            }
        },
        {
            path: "/learning/:id?/:loId?",
            name: "learning",
            component: learning,
            meta: {
                dynamicRoute: true,
                middleware: [
                    auth
                ]
            }
        }

    ],
    linkActiveClass: "active"
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router
