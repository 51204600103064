<template>
  <main id="login" class="login-container" v-if="!this.$route.query.id">
    <img
      src="../assets/images/ametros-learning-logo.svg"
      alt="Ametros Learning Logo"
      class="login-logo"
    />
    <div class="login-form" role="form">
      
      
      <div class="text-center">
        <button
          aria-label="Login with SAML"
          type="button"
          tabindex="0"
          class="btn btn-ametros"
          @click="initiateSamlLogin()"
          @keyup.enter="initiateSamlLogin()"
        >
          Login with SSO
        </button>
      </div>
      
    </div>
  </main>
</template>
<script>

export default {
  watch: {
            title: {
                immediate: true,
                handler() {
                    document.title = this.title;
                }
            }
        },
  name: "Login-SAML-component",
  data() {
    return {
      input: {
        email: ""
      },
      title :'Login with SAML',
      SamlUserId: "",
      url: process.env.VUE_APP_URL
    };
  },
  created() {
    
  },
  methods: {

    initiateSamlLogin() {
      window.location.href = 'https://backend.ametroslearning.com/api/v1/saml/login';
    }
  },
};
</script>

<style scoped lang="scss">
@import "../styles/variables.scss";
@import "../styles/mixin.scss";
.login-container {
  @include flexProperty(center, center, column);
  padding: 20px 0;
  min-height: 100vh;
  @include media-breakpoint(sm) {
    background-color: $theme-light-background;
  }
  a {
    text-decoration: underline $blue-color solid 1px !important;
  }
  .login-logo {
    width: 180px;
    margin: 0 0 30px;
  }
  .login-form {
    width: 100%;
    border: 1px solid #eaeaea;
    background-color: $white;
    border-radius: 4px;
    padding: 40px 50px;
    @include media-breakpoint(md) {
      width: 500px;
      background-color: $white;
      padding: 40px 50px;
    }
  }
  .register-area {
    display: none;
  }
  h1 {
    @include font-bold;
    text-transform: uppercase;
    font-size: 32px;
    margin: 0 0 30px;
    text-align: center;
  }
  label {
    @include font-medium;
  }
  input {
    border: 1px solid #eaeaea;
  }
  .forgot-show {
    position: absolute;
    right: 0;
    top: 10px;
    padding: 15px;
    font-size: 12px;
    height: 100%;
  }
}
</style>
