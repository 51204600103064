import Vue from 'vue';
import store from './store/index'
import App from './App.vue';
import router from './router/router'
import VModal from 'vue-js-modal';
import VueAnimated from '@codekraft-studio/vue-animated';
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import { Notifier } from '@airbrake/browser';
import VueTippy, { TippyComponent } from "vue-tippy";
import VueEllipseProgress from 'vue-ellipse-progress';
import ActionCableVue from 'actioncable-vue';
import VueSkipTo from '@vue-a11y/skip-to';
import VueFocusLoop from '@vue-a11y/focus-loop'
import VueSocialSharing from 'vue-social-sharing'

// Add additional themes.
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";
import KProgress from 'k-progress';
import "tabulator-tables/dist/css/tabulator.min.css";

// name is optional
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.config.productionTip = false



Vue.use(VModal, { dialog: true })
Vue.use(VueAnimated)
Vue.use(VueTippy);
Vue.component("tippy-component", TippyComponent);
Vue.component('k-progress', KProgress);
Vue.use(VueEllipseProgress);
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: process.env.VUE_APP_ACTION_CABLE_URL,
  connectImmediately: true,
});
Vue.use(VueSkipTo);
Vue.use(VueFocusLoop);
Vue.use(VueSocialSharing);

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
  });
  return formatter.format(value);
});

//if (process.env.NODE_ENV === 'development') {
  //const VueAxe = require('vue-axe').default
  //Vue.use(VueAxe)
//}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('en', en);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// Airbrake setup

if (process.env.NODE_ENV === 'production') {
  var airbrake = new Notifier({
    environment: 'production',
    projectId: process.env.VUE_APP_AIRBRAKE_PRODUCT_ID,
    projectKey: process.env.VUE_APP_AIRBRAKE_PRODUCT_KEY
  });
  
  Vue.config.errorHandler = function (err, vm, info) {
    airbrake.notify({
      error: err,
      params: {info: info}
    });
  }
}


