<template>
  <div class="learn-container" id="learn-container-area">
    <VueSkipTo to="#learn-content-area" label="Skip to main content" />
    <learn-sidebar
      :menus="menus"
      :enableIntegrity="enableIntegrity"
      :showIntro="showIntro"
      :card_type="card_type"
      :intros="intros"
      :user_roles="user_roles"
      :active_data="active_data"
    ></learn-sidebar>
    <main
      class="learn-content sidebar-border"
      id="learn-content-area"
      :key="stateKey"
      role="main"
    >
      <div class="learn-inner-content">
        <!-- common header start -->
        <div class="learn-header">
          <div class="mobile-menu" @click="toggleMenu()">
            <img src="../assets/images/mobileMenu.svg" alt="" />
          </div>
          <div class="learn-header-info" v-if="intros.attributes">
            <router-link
              to="/active"
              class="learn-header-icon"
              aria-label="Back to Dashboard"
            >
              <em class="icon-back"></em>
            </router-link>
            <div class="learn-header-content">
              <div class="learn-header-title">
                {{ intros.attributes.name }}
              </div>
              <div class="learn-header-text">
                {{ percentage }}% Module Completed
              </div>
              <div class="brand-images" v-if="brandImages.length > 0">
                <div v-for="logo in brandImages" :key="logo.id">
                  <span class="brand-image"
                    ><img
                      :src="logo.thumbnail"
                      :alt="logo.file_name.split('.')[0].replace(/-/g, ' ')"
                  /></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="menus.length > 0" class="ml-auto">
            <div
              class="modal-btn"
              v-if="this.hasCompletedModule"
            >
              <button
                class="btn btn-ametros px-4"
                @click="showFeedBack()"
                aria-label="See Final Feedback"
              >
                {{ "See Final Feedback" }}
              </button>
            </div>
          </div>
        </div>
        <learning-intro
          v-if="showIntro && !card_type && intros && intros.attributes"
          :intros="intros"
          :menus="menus"
        ></learning-intro>

        <learning-integrity-intro
          v-if="showIntegrity && !card_type && intros && intros.attributes"
          :intros="intros"
          :menus="menus"
          :studentIntegrityClicked="studentIntegrityClicked"
          :integrityStudentAgree="integrityStudentAgree"
        >
        </learning-integrity-intro>
        <div v-for="menu in menus" :key="menu.id">
          <div
            class="learn-steps"
            v-if="card_type === 'text' && active_data.id === menu.id"
          >
            <richText
              :active_data="active_data"
              :nextStatus="nextStatus"
              id="text"
            />
          </div>

          <div
            class="learn-steps"
            v-if="card_type === 'slide' && active_data.id === menu.id"
          >
            <slide
              :active_data="active_data"
              :nextStatus="nextStatus"
              id="slide"
            />
          </div>

          <div
            class="learn-steps"
            v-if="
              card_type === 'email' &&
              learningObjectType === 'plot_point' &&
              active_data.id === menu.id
            "
          >
            <email
              :active_data="active_data"
              :nextStatus="nextStatus"
              id="email"
            />
          </div>

          <div
            class="learn-steps"
            v-if="card_type === 'video' && active_data.id === menu.id"
          >
            <Video
              :active_data="active_data"
              :nextStatus="nextStatus"
              id="video"
            />
          </div>

          <div
            class="learn-steps"
            v-if="card_type === 'file' && active_data.id === menu.id"
          >
            <pdf
              :active_data="active_data"
              :nextStatus="nextStatus"
              id="file"
              v-if="active_data.attributes.learning_object.attributes.card_detail.attributes.is_pdf === true"
            />

            <csv
              :active_data="active_data"
              :nextStatus="nextStatus"
              id="file"
              v-if="active_data.attributes.learning_object.attributes.card_detail.attributes.is_csv === true"
            />

          </div>

          <div
            class="learn-steps"
            v-if="card_type === 'dialogic' && active_data.id === menu.id"
          >
            <dialogic
              :active_data="active_data"
              :nextStatus="nextStatus"
              :module_id="id"
              id="file"
              ref="dialogicComponent"
              @dialogicFinished="completeDialogicLO()"
            />
            <!-- Should this be in the dialogic component? 
            <div v-if="loReset === true" class="learn-object-pagination">
              <div class="learn-btn ml-auto">
                <a to class="btn secondary ml-auto" @click="next">
                  {{ !nextStatus ? "Next" : "See Final Feedback" }}
                  <em class="icon-arrow-right"></em>
                </a>
              </div>
            </div>-->
          </div>

          <div
            class="learn-steps"
            v-if="card_type === 'chat' && active_data.id === menu.id"
          >
            <chat
              :active_data="active_data"
              :nextStatus="nextStatus"
              id="file"
            />
          </div>

          <div
            class="learn-steps"
            v-if="card_type === 'submission' && active_data.id === menu.id"
          >
            <submission
              :active_data="active_data"
              :nextStatus="nextStatus"
              id="submission"
            />
          </div>

          <div
            class="learn-steps"
            v-if="card_type === 'llm_chat' && active_data.id === menu.id"
          >
            <llm-chat
              :active_data="active_data"
              :nextStatus="nextStatus"
              :user_roles="user_roles"
              id="llm_chat"
            />
          </div>

          <div
            class="learn-steps"
            v-if="card_type === 'llm_dialogic' && active_data.id === menu.id"
          >
            <llm-dialogic
              :active_data="active_data"
              :nextStatus="nextStatus"
              :user_roles="user_roles"
              id="llm_dialogic"
            />
          </div>

          <div
            class="learn-steps"
            v-if="card_type === 'llm_email' && active_data.id === menu.id"
          >
            <llm-email
              :active_data="active_data"
              :nextStatus="nextStatus"
              :user_roles="user_roles"
              id="llm_email"
            />
          </div>

          <div
            class="learn-steps"
            v-if="
              card_type === 'submission_email' && active_data.id === menu.id
            "
          >
            <submissionEmail
              :active_data="active_data"
              :nextStatus="nextStatus"
              :percentage="percentage"
              id="submission_email"
            />
          </div>

          <div
            class="learn-steps"
            v-if="card_type === 'coding' && active_data.id === menu.id"
          >
            <coding
              :active_data="active_data"
              :nextStatus="nextStatus"
              id="coding"
            />
          </div>
        </div>

        <!-- External_API start -->
        <div class="learn-steps" v-if="card_type === 'external_api'">
          <h1 class="learn-title">{{ externalApiName }}</h1>
          <p v-html="externalApiDesc" class="learn-info voice-info"></p>

          <div v-if="externalApiStatus === 'connected'">
            <div class="alert alert-success">
              <strong>Connection Succesful!</strong>
            </div>
          </div>

          <div v-if="externalApiStatus === 'feedback'">
            <p class="learn-info voice-info">
              Your submission has been assessed.
            </p>
          </div>

          <div
            class="learn-object-editor"
            v-if="externalApiStatus != 'feedback'"
          >
            <div class="learn-object-info-learning">
              <h4>AWS API Access Key</h4>
            </div>
          </div>

          <div class="row" v-if="externalApiStatus != 'feedback'">
            <div class="col-10">
              <div class="learn-voice-textarea">
                <input class="form-control" rows="6" v-model="awsAccess" />
              </div>
            </div>
            <br />
            <div v-if="externalApiStatus === 'connected'" class="col">
              <img src="../assets/images/tick-icon.svg" alt="" />
            </div>
            <br />
          </div>

          <div
            class="learn-object-editor"
            v-if="externalApiStatus != 'feedback'"
          >
            <div class="learn-object-info-learning">
              <h4>AWS Secret Access Key</h4>
            </div>
          </div>

          <div class="row" v-if="externalApiStatus != 'feedback'">
            <div class="col-10">
              <div class="learn-voice-textarea">
                <input class="form-control" rows="6" v-model="awsSecret" />
              </div>
            </div>
            <div v-if="externalApiStatus === 'connected'" class="col">
              <img src="../assets/images/tick-icon.svg" alt="" />
            </div>
          </div>

          <div
            v-if="
              externalApiStatus === 'begin' || externalApiStatus === 'wrong'
            "
            class="learn-object-pagination"
          >
            <button
              class="btn btn-ametros ml-auto"
              @click="externalapiConnect()"
            >
              Connect
            </button>
          </div>
          <div
            v-if="externalApiStatus === 'connected'"
            class="learn-object-pagination"
          >
            <button class="btn default" @click="externalApiEditKeys()">
              Edit API Keys
            </button>
            <button
              class="btn btn-ametros ml-auto"
              @click="externalapiSubmit()"
            >
              Submit AWS Data
            </button>
          </div>

          <div
            v-if="externalApiStatus === 'feedback'"
            class="learn-object-pagination"
          >
            <button
              class="btn btn-ametros ml-auto"
              @click="getExternalShowFeedback()"
            >
              See Feedback
            </button>
          </div>
          <div
            v-if="externalApiStatus === 'feedback'"
            class="learn-object-pagination"
          >
            <div class="learn-btn ml-auto">
              <button
                class="btn secondary ml-auto"
                @click="next"
                :aria-label="!nextStatus ? 'Next' : 'See Final Feedback'"
                tabindex="0"
              >
                {{ !nextStatus ? "Next" : "See Final Feedback" }}
                <em class="icon-arrow-right"></em>
              </button>
            </div>
          </div>
        </div>

        <!-- External_API end -->

        <div class="learn-steps" v-if="card_type === 'quiz'">
          <h1 class="learn-title">{{ quizName }}</h1>
          <div v-if="stickyNote.length">
            <div v-for="(notes, index) in stickyNote" :key="index">
              <div class="learn-notes my-3" v-if="notes && notes.length">
                <p>
                  <img src="../assets/images/icon-yellownote.svg" alt="" />Note
                </p>
                <p v-html="notes"></p>
              </div>
            </div>
          </div>
          <p v-html="quizDesc" class="learn-info voice-info"></p>
          <div v-for="(question, i) in questions" :key="question.id">
            <div
              class="learn-object"
              v-if="i === questionId || question.id === leftQuizId"
            >
              <div class="learn-object-progress">
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style="width: 10%"
                    :style="{
                      width: [((i + 1) * 100) / questions.length + '%'],
                    }"
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :aria-label="((i + 1) * 100) / questions.length"
                  ></div>
                </div>
                <div class="learn-object-question">
                  Question {{ i + 1 }}/{{ questions.length }}
                </div>
              </div>
              <div class="learn-object-editor">
                <div class="learn-object-info-learning">
                  <div v-html="question.attributes.question"></div>
                </div>
                <div class="learn-object-form learn-voice-form">
                  <div
                    class="learn-voice-textarea"
                    v-if="question.attributes.question_type === 'long_answer'"
                  >
                    <textarea
                      class="form-control"
                      rows="6"
                      v-model="question.answer"
                      @input="quizAnswer(question.answer)"
                      aria-label="quiz answer"
                    ></textarea>
                  </div>

                  <div
                    class="learn-voice-textarea"
                    v-if="question.attributes.question_type === 'ai_text_rows'"
                  >
                    <div>
                      <div
                        v-for="(item, index) of question.answer"
                        :key="index"
                      >
                        <textarea
                          class="form-control form-textarea"
                          v-model="item.responseMultiRow"
                          aria-label="answers area"
                        ></textarea>
                        <br />
                      </div>
                    </div>
                  </div>

                  <div
                    class="learn-voice-input pt-3"
                    v-if="question.attributes.question_type === 'numeric'"
                  >
                    <input
                      type="number"
                      class="form-control col-12 col-lg-6"
                      placeholder="Add Numeric Value"
                      aria-label="Add Numeric Value"
                      v-model="question.answer"
                      @input="quizAnswer(question.answer)"
                      :disabled="resultStatus"
                      onpaste="return false;"
                      onkeydown="javascript: return event.keyCode === 69 ? false : true"
                    />
                  </div>

                  <div
                    class="learn-voice-input pt-3"
                    v-if="question.attributes.question_type === 'range'"
                  >
                    <input
                      type="number"
                      class="form-control col-12 col-lg-6"
                      placeholder="Add Numeric Value"
                      aria-label="Add Numeric Value"
                      v-model="question.answer"
                      :disabled="resultStatus"
                      onpaste="return false;"
                      @input="quizAnswer(question.answer)"
                      onkeydown="javascript: return event.keyCode === 69 ? false : true"
                    />
                  </div>

                  <div
                    class="learn-voice-checkboxes"
                    v-if="
                      question.attributes.question_type === 'multiple_choice'
                    "
                  >
                    <div class="form-checkboxes d-block">
                      <div
                        class="form-checkboxes-items"
                        v-for="option in question.attributes.mcq_options"
                        :key="option.id"
                      >
                        <label class="custom-checkbox">
                          {{ option.attributes.option }}
                          <input
                            type="radio"
                            aria-label="option"
                            :disabled="resultStatus"
                            v-model="question.mcqStatus"
                            @change="selectMcqOption(i, question, option.id)"
                            :checked="option.id === question.answer"
                            :value="option.id"
                          />
                          <span class="checkmark circle"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="learn-object-pagination">
                  <button
                    class="btn default"
                    :disabled="resultStatus"
                    v-if="i > 0 && !resultStatus"
                    @click="prevQuestion(question.id, i)"
                  >
                    Previous
                  </button>
                  <button
                    class="btn btn-ametros ml-auto"
                    :disabled="disableNextBtn"
                    @click="
                      nextQuestion(
                        question.id,
                        question.answer,
                        i,
                        question,
                        'next',
                        question.attributes.question_type
                      )
                    "
                    v-if="i !== questions.length - 1"
                  >
                    Next
                  </button>
                  <button
                    v-if="i === questions.length - 1 && !resultStatus"
                    @click="$modal.show('quizSubmissionConfirmation')"
                    class="btn btn-ametros ml-auto"
                    aria-label="submit quiz"
                  >
                    Submit
                  </button>

                  <button
                    @click="resetQuizInteraction()"
                    class="btn secondary"
                    v-if="
                      user_roles.includes('testing-internal') && resultStatus
                    "
                  >
                    Reset Quiz
                  </button>
                  <button
                    v-if="resultStatus && i === questions.length - 1"
                    @click="
                      nextQuestion(
                        question.id,
                        question.answer,
                        i,
                        question,
                        'result',
                        question.attributes.question_type
                      )
                    "
                    class="btn btn-ametros ml-auto"
                    aria-label="See Result"
                  >
                    See Result
                  </button>
                </div>
              </div>
            </div>
            <lo-buttons
              v-if="resultStatus && i === questions.length - 1"
              :nextStatus="nextStatus"
              :nextMethod="next"
              text="See Final Feedback"
              :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
            ></lo-buttons>
          </div>
          <lo-buttons
            v-if="questions.length === 0"
            :nextStatus="nextStatus"
            :nextMethod="next"
            text="See Final Feedback"
            :showCertificateOfCompletion="active_data.attributes.enable_certificate_of_completion"
          ></lo-buttons>
        </div>

        <div
          class="learn-steps"
          v-if="card_type === 'email' && learningObjectType != 'plot_point'"
        >
          <email-no-plotpoint
            :active_data="active_data"
            :nextStatus="nextStatus"
            :module_id="id"
            id="email_no_plotpoint"
          />
        </div>
      </div>
    </main>
    <!-- External API cannot Connect modal start -->
    <modal
      name="awsNotConnect"
      aria-label="awsNotConnect"
      role="dialog"
      :width="400"
      height="auto"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-defaultt">
          <div class="text-end">
            <a class="close">
              <em class="icon-close" @click="$modal.hide('awsNotConnect')"></em>
            </a>
          </div>
          <div class="modal-body">
            <p>Could not connect to AWS with the provided API keys</p>
            <p>Please check your API keys and try again</p>
          </div>
        </div>
      </FocusLoop>
    </modal>
    <!-- External API cannot Connect modal end -->
    <!-- AWS submit modal start -->

    <modal
      name="awsSubmit"
      aria-label="awsSubmit"
      role="dialog"
      :width="600"
      height="auto"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-defaultt">
          <div class="text-end">
            <button class="close" @click="$modal.hide('awsSubmit')">
              <em class="icon-close"></em>
            </button>
          </div>
          <div class="modal-body">
            <p>
              Are you sure you want to submit your AWS data for evaluation? You
              can only submit once.
            </p>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              @click="$modal.hide('awsSubmit')"
              data-dismiss="modal"
              class="btn default"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-ametros"
              v-on:click="externalapiSubmitConfirmed"
            >
              Yes, submit
            </button>
          </div>
        </div>
      </FocusLoop>
    </modal>
    <!-- AWS submit end -->
    <!-- AWS submit confirmed modal start -->

    <modal
      name="awsSubmitConfirmed"
      aria-label="awsSubmitConfirmed"
      role="dialog"
      :width="500"
      height="auto"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-defaultt">
          <div v-if="externalApiCompleted">
            <div class="text-end">
              <button
                class="close"
                @click="getExternalFeedback"
                aria-label="get external feedback"
              >
                <em class="icon-close"></em>
                <span class="visually-hidden">Get External Feedback</span>
              </button>
            </div>
          </div>

          <div v-if="!externalApiCompleted">
            <div class="modal-body">
              <p>Retrieving and processing your AWS submission.</p>
            </div>
            <br />
            <div>
              <vue-ellipse-progress
                :progress="externalApiProgress"
                color="#3dbc9e"
                fontColor="white"
              >
              </vue-ellipse-progress>
            </div>
          </div>

          <div v-if="externalApiCompleted">
            <div class="modal-body">
              <p>Your AWS data has been successfully processed</p>
            </div>
            <br />
            <div class="text-center">
              <img src="../assets/images/tick-icon.svg" alt="" />
            </div>
          </div>
        </div>
      </FocusLoop>
    </modal>
    <!-- AWS submit confirmed end -->

    <!-- AWS Feedback start-->
    <modal
      name="awsFeedback"
      aria-label="awsFeedback"
      role="dialog"
      class="modal-fullScreen"
      width="100%"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-feedback-content modal-score-content">
          <button
            type="button"
            class="modal-close"
            aria-label="Close Modal"
            @click="$modal.hide('awsFeedback')"
          >
            <em class="icon-cross"></em>
          </button>

          <VuePerfectScrollbar class="modal-slide-content" :settings="settings">
            <div class="modal-body">
              <h1 class="w-100">Feedback</h1>
              <br />
              <br />

              <div
                v-for="assessment of externalEvaluation"
                :key="assessment.externalApiAssmntBlockId"
              >
                <h2>{{ assessment.externalApiAssmntBlockName }}</h2>
                <br />
                <br />
                <div class="row">
                  <div class="col">
                    <span class="modal-score-icon">
                      <img
                        v-if="assessment.assessmentIconUrl"
                        :src="assessment.assessmentIconUrl"
                        :alt="assessment.assessmentLabel"
                      />
                    </span>
                  </div>
                  <div class="col-10">
                    <p>{{ assessment.assessmentLabel }}</p>
                    <br />
                    <p>{{ assessment.debriefReceived }}</p>
                  </div>
                </div>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </FocusLoop>
    </modal>
    <modal
      name="quizSubmissionConfirmation"
      aria-label="quizSubmissionConfirmation"
      role="dialog"
      height="auto"
      :width="350"
      :scrollable="true"
      @before-open="beforeQuizOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-delete-content">
          <div class="modal-body modal-delete-body">
            Once you submit the quiz, you will no longer be able to change your
            answers. Are you sure?
          </div>
          <div class="d-flex flex-row justify-content-between mt-4">
            <button
              type="button"
              @click="$modal.hide('quizSubmissionConfirmation')"
              class="btn small default mr-3"
            >
              Cancel
            </button>
            <button
              type="button"
              @click="submitQuiz(), $modal.hide('quizSubmissionConfirmation')"
              class="btn small btn-success"
            >
              OK
            </button>
          </div>
        </div>
      </FocusLoop>
    </modal>

    <modal
      name="holdingTask"
      aria-label="holdingTask"
      role="dialog"
      :width="400"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-holding-content">
          <button
            type="button"
            class="modal-close"
            aria-label="Close Modal"
            @click="$modal.hide('holdingTask')"
          >
            <em class="icon-cross"></em>
          </button>
          <div class="modal-title">
            <h1>Holding Task</h1>
          </div>
          <div class="modal-body">
            <p v-html="holdOnMessage"></p>
            <!-- <p class="inClass-work">In-class work.</p> -->
          </div>
        </div>
      </FocusLoop>
    </modal>

    <modal
      name="awaitResults"
      aria-label="awaitResults"
      role="dialog"
      :width="400"
      height="auto"
    >
      <vue-ellipse-progress
        :progress="quizEvalProgress"
        color="#3dbc9e"
        fontColor="white"
      >
        <template>
          <span> Total Questions: {{ questions.length }} </span>
          <div>Evaluation Completed: {{ quizQuesEvaluated }}</div>
        </template>
      </vue-ellipse-progress>
    </modal>
    <modal
      name="seeResult"
      aria-label="seeResult"
      role="dialog"
      class="modal-fullScreen"
      width="100%"
      height="auto"
      :scrollable="true"
      @before-open="beforeQuizOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-feedback-content modal-score-content">
          <button
            type="button"
            class="modal-close"
            aria-label="Close Modal"
            @click="$modal.hide('seeResult')"
          >
            <em class="icon-cross"></em>
          </button>

          <VuePerfectScrollbar
            class="modal-slide-content"
            :settings="settings"
            v-if="!hideScore"
          >
            <div class="modal-body">
              <div class="modal-score-status">{{ tallyMessage }}</div>
              <h1 class="w-100">{{ overallMessage }}</h1>
              <div
                class="modal-score-strip"
                v-if="overAllAssesment && overAllAssesment.display_feedback"
              >
                <span class="modal-score-icon">
                  <img
                    v-if="overAllAssesment.assessment_icon_url"
                    :src="overAllAssesment.assessment_icon_url"
                    :alt="overAllAssesment.assessment_label"
                  />
                  {{ overAllAssesment.assessment_label }}
                </span>
                <p
                  v-if="overAllAssesment.feedback"
                  v-html="overAllAssesment.feedback"
                ></p>
              </div>
              <div class="modal-score-lists" v-if="results.length > 0">
                <div
                  class="modal-score-items"
                  v-for="(result, i) in results"
                  :key="result.id"
                >
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="modal-score-question">
                        <div class="modal-score-title">Question {{ i + 1 }}</div>
                        <p
                          v-html="result.attributes.question.attributes.question"
                        >
                        </p>
                      </div>
                      <div
                        class="modal-score-question"
                        v-if="
                          result.attributes.question.attributes.question_type !=
                            'multiple_choice' &&
                          result.attributes.question.attributes.question_type !=
                            'ai_text_rows'
                        "
                      >
                        <div class="modal-score-title">Your Answer</div>
                        <p>{{ result.attributes.learner_answer }}</p>
                      </div>
                      <div
                        class="modal-score-question"
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'multiple_choice'
                        "
                      >
                        <div class="modal-score-title">Your Answer</div>
                        <p>
                          {{
                            result.attributes.learner_chosed_option.attributes
                              .option
                          }}
                        </p>
                      </div>

                      <div
                        class="modal-score-question"
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'ai_text_rows'
                        "
                      >
                        <div class="modal-score-title">Your Answer</div>
                        <div
                          v-for="(item, index) of result.attributes
                            .text_row_responses"
                          :key="index"
                        >
                          <p>{{ item.attributes.response }}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-lg-6"
                      v-if="result.attributes.is_correct"
                    >
                      <div class="modal-score-anwser">
                        <img src="../assets/images/tick-icon.svg" alt="" />
                        <span v-if="result.attributes.question.attributes.quiz_feedback
                            .attributes.right_ans_label !== null">{{
                          result.attributes.question.attributes.quiz_feedback
                            .attributes.right_ans_label
                        }}</span>
                        <span v-else>Correct</span>
                      </div>
                      <div class="modal-score-placheolder">
                        <!-- Correct Answer -->
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                    </div>
                    <div
                      class="col-12 col-lg-6"
                      v-if="!result.attributes.is_correct"
                    >
                      <div class="modal-score-anwser">
                        <img src="../assets/images/cross-icon.svg" alt="" />
                        <span v-if="result.attributes.question.attributes.quiz_feedback
                            .attributes.wrong_ans_label !== null">{{
                          result.attributes.question.attributes.quiz_feedback
                            .attributes.wrong_ans_label
                        }}</span>
                        <span v-else>Incorrect</span>
                      </div>
                      <div
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'numeric'
                        "
                        class="modal-score-placheolder"
                      >
                        Correct answer is
                        {{
                          result.attributes.question.attributes.numeric_answer
                            .attributes.answer
                        }}
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                      <div
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'multiple_choice'
                        "
                        class="modal-score-placheolder"
                      >
                        Correct answer is
                        {{
                          result.attributes.correct_option[0].attributes.option
                        }}
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                      <div
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'long_answer'
                        "
                        class="modal-score-placheolder"
                      >
                        <!-- Correct answer is {{result.attributes.correct_option[0].attributes.option}} -->
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                      <div
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'ai_text_rows'
                        "
                        class="modal-score-placheolder"
                      >
                        <!-- Correct answer is {{result.attributes.correct_option[0].attributes.option}} -->
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                      <div
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'range'
                        "
                        class="modal-score-placheolder"
                      >
                        Correct answer range from
                        {{
                          result.attributes.question.attributes.range_answer
                            .attributes.low_range
                        }}
                        to
                        {{
                          result.attributes.question.attributes.range_answer
                            .attributes.high_range
                        }}
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                    </div>
                    <div
                      v-if="
                        !result.attributes.is_correct ||
                        showCorrectAdaptiveFeedback
                      "
                    >
                      <div
                        class="col-12"
                        v-for="item in result.attributes.question.attributes
                          .adaptive_contents"
                        :key="item.id"
                      >
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'slide_learn_obj'
                          "
                        >
                          <h5>Suggested Content</h5>
                          <div class="suggestion-row">
                            <div class="suggestion-slide">
                              <button
                                @click="
                                  $modal.show('suggestedContentModal', {
                                    data: item.attributes.content_details
                                      .attributes.slider_images,
                                    type: 'slide',
                                    title: item.attributes,
                                  })
                                "
                                aria-label="show suggested content"
                              >
                                <img
                                  :src="
                                    item.attributes.content_details.attributes
                                      .slider_images[0].attributes.resource_url
                                      .thumbnail
                                  "
                                  :alt="
                                    item.attributes.content_details.attributes
                                      .slider_images[0].attributes.global_resource
                                      .attributes.tag_list
                                  "
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'text_learn_obj'
                          "
                        >
                          <h5>Suggested Content</h5>
                          <div class="suggestion-row">
                            <div
                              class="suggestion-slide"
                              v-html="
                                item.attributes.content_details.attributes.title
                              "
                            ></div>
                          </div>
                        </div>
                        <!-- suggestion container pdf  -->
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'file_learn_obj'
                          "
                        >
                          <h5>Suggested Content(file)</h5>
                          <div class="suggestion-row">
                            <div class="suggestion-pdf">
                              <button
                                @click="
                                  $modal.show('pdfModal', {
                                    pdfUrl:
                                      item.attributes.content_details.attributes
                                        .resource_url,
                                    label:
                                      item.attributes.content_details.attributes
                                        .resource_file_name,
                                  })
                                "
                                aria-label="show pdf"
                              >
                                <span class="truncate">{{
                                  item.attributes.content_details.attributes
                                    .resource_file_name
                                }}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <!-- suggestion container video  -->
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'video_learn_obj'
                          "
                        >
                          <h5>Suggested Content(video)</h5>
                          <div class="suggestion-row">
                            <div class="suggestion-video">
                              <button
                                @click="
                                  $modal.show('suggestedContentModal', {
                                    data: item.attributes.content_details
                                      .attributes.global_video.attributes
                                      .wistia_code,
                                    type: 'video',
                                    title: item.attributes,
                                  })
                                "
                                aria-label="show suggested content"
                              >
                                <img
                                  :src="
                                    item.attributes.content_details.attributes
                                      .global_video.attributes
                                      .wistia_thumbnail_url
                                  "
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-btn">
                <button
                  class="btn secondary"
                  @click="
                    hide('seeResult');
                    next();
                  "
                  :aria-label="!nextStatus ? 'Next' : 'See Final Feedback'"
                  tabindex="0"
                >
                  {{ !nextStatus ? "Next" : "See Final Feedback" }}
                  <em class="icon-arrow-right"></em>
                </button>
              </div>
            </div>
          </VuePerfectScrollbar>

          <VuePerfectScrollbar
            class="modal-slide-content"
            :settings="settings"
            v-if="hideScore"
          >
            <div class="modal-body">
              <div class="modal-score-status">{{ tallyMessage }}</div>
              <h3 class="w-100">{{ overallMessage }}</h3>
              <div
                class="modal-score-strip"
                v-if="overAllAssesment && overAllAssesment.display_feedback"
              >
                <span class="modal-score-icon">
                  <img
                    v-if="overAllAssesment.assessment_icon_url"
                    :src="overAllAssesment.assessment_icon_url"
                    :alt="overAllAssesment.assessment_label"
                  />
                  {{ overAllAssesment.assessment_label }}
                </span>
                <p
                  v-if="overAllAssesment.feedback"
                  v-html="overAllAssesment.feedback"
                ></p>
              </div>
              <div class="modal-score-lists" v-if="results.length > 0">
                <div
                  class="modal-score-items"
                  v-for="(result, i) in results"
                  :key="result.id"
                >
                  <div class="row">
                    <div class="col-12 col-lg-6">
                      <div class="modal-score-question">
                        <div class="modal-score-title">Question {{ i + 1 }}</div>
                        <p
                          v-html="result.attributes.question.attributes.question"
                        >
                        </p>
                      </div>
                      <div
                        class="modal-score-question"
                        v-if="
                          result.attributes.question.attributes.question_type !=
                            'multiple_choice' &&
                          result.attributes.question.attributes.question_type !=
                            'ai_text_rows'
                        "
                      >
                        <div class="modal-score-title">Your Answer</div>
                        <p>{{ result.attributes.learner_answer }}</p>
                      </div>
                      <div
                        class="modal-score-question"
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'multiple_choice'
                        "
                      >
                        <div class="modal-score-title">Your Answer</div>
                        <p>
                          {{
                            result.attributes.learner_chosed_option.attributes
                              .option
                          }}
                        </p>
                      </div>

                      <div
                        class="modal-score-question"
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'ai_text_rows'
                        "
                      >
                        <div class="modal-score-title">Your Answer</div>
                        <div
                          v-for="(item, index) of result.attributes
                            .text_row_responses"
                          :key="index"
                        >
                          <p>{{ item.attributes.response }}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-lg-6"
                      v-if="result.attributes.is_correct"
                    >
                      <div class="modal-score-placheolder">
                        <!-- Correct Answer -->
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                    </div>
                    <div
                      class="col-12 col-lg-6"
                      v-if="!result.attributes.is_correct"
                    >
                      <div
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'numeric'
                        "
                        class="modal-score-placheolder"
                      >
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                      <div
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'multiple_choice'
                        "
                        class="modal-score-placheolder"
                      >
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                      <div
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'long_answer'
                        "
                        class="modal-score-placheolder"
                      >
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                      <div
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'ai_text_rows'
                        "
                        class="modal-score-placheolder"
                      >
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                      <div
                        v-if="
                          result.attributes.question.attributes.question_type ==
                          'range'
                        "
                        class="modal-score-placheolder"
                      >
                        <p
                          class="feedback_tag"
                          v-html="result.attributes.feedback_received"
                        ></p>
                      </div>
                    </div>
                    <div v-if="!result.attributes.is_correct">
                      <div
                        class="col-12"
                        v-for="item in result.attributes.question.attributes
                          .adaptive_contents"
                        :key="item.id"
                      >
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'slide_learn_obj'
                          "
                        >
                          <h5>Suggested Content</h5>
                          <div class="suggestion-row">
                            <div class="suggestion-slide">
                              <button
                                @click="
                                  $modal.show('suggestedContentModal', {
                                    data: item.attributes.content_details
                                      .attributes.slider_images,
                                    type: 'slide',
                                    title: item.attributes,
                                  })
                                "
                                aria-label="show suggested content"
                              >
                                <img
                                  :src="
                                    item.attributes.content_details.attributes
                                      .slider_images[0].attributes.resource_url
                                      .thumbnail
                                  "
                                  :alt="
                                    item.attributes.content_details.attributes
                                      .slider_images[0].attributes.global_resource
                                      .attributes.tag_list
                                  "
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'text_learn_obj'
                          "
                        >
                          <h5>Suggested Content</h5>
                          <div class="suggestion-row">
                            <div
                              class="suggestion-slide"
                              v-html="
                                item.attributes.content_details.attributes.title
                              "
                            ></div>
                          </div>
                        </div>
                        <!-- suggestion container pdf  -->
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'file_learn_obj'
                          "
                        >
                          <h5>Suggested Content(file)</h5>
                          <div class="suggestion-row">
                            <div class="suggestion-pdf">
                              <button
                                @click="
                                  $modal.show('pdfModal', {
                                    pdfUrl:
                                      item.attributes.content_details.attributes
                                        .resource_url,
                                    label:
                                      item.attributes.content_details.attributes
                                        .resource_file_name,
                                  })
                                "
                                aria-label="show pdf"
                              >
                                <span class="truncate">{{
                                  item.attributes.content_details.attributes
                                    .resource_file_name
                                }}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <!-- suggestion container video  -->
                        <div
                          class="suggestion-container"
                          v-if="
                            item.attributes.content_details.type ==
                            'video_learn_obj'
                          "
                        >
                          <h5>Suggested Content(video)</h5>
                          <div class="suggestion-row">
                            <div class="suggestion-video">
                              <button
                                @click="
                                  $modal.show('suggestedContentModal', {
                                    data: item.attributes.content_details
                                      .attributes.global_video.attributes
                                      .wistia_code,
                                    type: 'video',
                                    title: item.attributes,
                                  })
                                "
                                aria-label="show suggested content"
                              >
                                <img
                                  :src="
                                    item.attributes.content_details.attributes
                                      .global_video.attributes
                                      .wistia_thumbnail_url
                                  "
                                  alt=""
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-btn">
                <button
                  class="btn secondary"
                  @click="
                    hide('seeResult');
                    next();
                  "
                  :aria-label="!nextStatus ? 'Next' : 'See Final Feedback'"
                  tabindex="0"
                >
                  {{ !nextStatus ? "Next" : "See Final Feedback" }}
                  <em class="icon-arrow-right"></em>
                </button>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </FocusLoop>
    </modal>

    <!-- feedback Task modal start -->
    <modal
      name="feedbackTaskFinal"
      aria-label="feedbackTaskFinal"
      role="dialog"
      class="modal-fullScreen"
      width="100%"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div
          class="modal-content modal-feedback-content modal-score-content"
          v-if="emailfeedbackResponse"
        >
          <button
            type="button"
            class="modal-close"
            @click="$modal.hide('feedbackTaskFinal')"
            aria-label="close modal"
          >
            <em class="icon-cross"></em>
          </button>
          <VuePerfectScrollbar class="modal-slide-content" :settings="settings">
            <div class="modal-body">
              <div class="d-flex flex-wrap w-100 justify-content-between">
                <h1>Feedback</h1>
                <div class="d-flex">
                  <p>
                    Number of iterations:{{
                      emailfeedbackResponse.attributes.iteration
                    }}
                  </p>
                  <button
                    @click="$modal.show('iterationInfo')"
                    class="feedback-info"
                    aria-label="show iteration info"
                  >
                    i
                    <span class="visually-hidden">show iteration info</span>
                  </button>
                </div>
              </div>
              <div
                class="modal-score-strip mb-4"
                v-if="
                  overall_assmnt_item &&
                  overall_assmnt_item.display_feedback &&
                  emailfeedbackResponse.attributes.display_overall_assessment
                "
              >
                <span class="modal-score-icon" v-if="!hideAssessmentLabel">
                  <img
                    v-if="overall_assmnt_item.assessment_icon_url"
                    :src="overall_assmnt_item.assessment_icon_url"
                    :alt="overall_assmnt_item.assessment_label"
                  />
                  {{ overall_assmnt_item.assessment_label }}
                </span>
                <p v-html="overall_assmnt_item.feedback"></p>
              </div>

              <div
                class="feedback-items mastery"
                v-for="values in emailfeedbackResponse.attributes
                  .learner_debrief_items"
                :key="values.id"
              >
                <div
                  class="feedback-icon"
                  v-if="!emailfeedbackResponse.attributes.hide_assessment_label"
                >
                  <img
                    :src="
                      values.attributes.email_assessment_item.attributes
                        .assessment_icon_url
                    "
                    alt=""
                  />
                </div>
                <div class="feedback-content">
                  <div
                    class="feedback-head"
                    v-if="!emailfeedbackResponse.attributes.hide_assessment_label"
                  >
                    {{
                      values.attributes.email_assessment_item.attributes
                        .assessment_label
                    }}
                  </div>
                  <h4>
                    {{ values.attributes.global_skill.name }}
                    <button
                      @click="
                        modalInfoModal(
                          'feedbackInfo',
                          values.attributes.global_skill.name,
                          values
                        )
                      "
                      class="feedback-info"
                      aria-label="show feedback info"
                    >
                      i
                      <span class="visually-hidden">show feedback info</span>
                    </button>
                  </h4>
                  <div
                    class="emailBody"
                    v-html="values.attributes.debrief_content"
                  ></div>
                  <div
                    class="col-12 px-0"
                    v-for="item in values.attributes.email_assessment_item
                      .attributes.adaptive_contents"
                    :key="item.id"
                  >
                    <!-- suggestion container slide  -->
                    <div
                      class="suggestion-container"
                      v-if="
                        item.attributes.content_details.type == 'slide_learn_obj'
                      "
                    >
                      <h5>Suggested Content</h5>
                      <div class="suggestion-row">
                        <div class="suggestion-slide">
                          <button
                            @click="
                              $modal.show('suggestedContentModal', {
                                data: item.attributes.content_details.attributes
                                  .slider_images,
                                type: 'slide',
                                title: item.attributes,
                              })
                            "
                            aria-label="show suggested content"
                          >
                            <img
                              :src="
                                item.attributes.content_details.attributes
                                  .slider_images[0].attributes.resource_url
                                  .thumbnail
                              "
                              :alt="
                                item.attributes.content_details.attributes
                                  .slider_images[0].attributes.global_resource
                                  .attributes.tag_list
                              "
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="suggestion-container"
                      v-if="
                        item.attributes.content_details.type == 'text_learn_obj'
                      "
                    >
                      <h5>Suggested Content</h5>
                      <div class="suggestion-row">
                        <div
                          class="suggestion-slide"
                          v-html="
                            item.attributes.content_details.attributes.title
                          "
                        ></div>
                      </div>
                    </div>
                    <!-- suggestion container pdf  -->
                    <div
                      class="suggestion-container"
                      v-if="
                        item.attributes.content_details.type == 'file_learn_obj'
                      "
                    >
                      <h5>Suggested Content(file)</h5>
                      <div class="suggestion-row">
                        <div class="suggestion-pdf">
                          <button
                            class="btn pdf-download"
                            @click.prevent="
                              downloadPdf(
                                item.attributes.content_details.attributes
                                  .resource_url,
                                'Suggested_Content'
                              )
                            "
                            aria-label="download PDF"
                          >
                            <span class="truncate">{{
                              item.attributes.content_details.attributes
                                .resource_file_name
                            }}</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      class="suggestion-container"
                      v-if="
                        item.attributes.content_details.type == 'video_learn_obj'
                      "
                    >
                      <h5>Suggested Content(video)</h5>
                      <div class="suggestion-row">
                        <div class="suggestion-video">
                          <button
                            @click="
                              $modal.show('suggestedContentModal', {
                                data: item.attributes.content_details.attributes
                                  .global_video.attributes.wistia_code,
                                type: 'video',
                                title: item.attributes,
                              })
                            "
                            aria-label="show suggested content"
                          >
                            <img
                              :src="
                                item.attributes.content_details.attributes
                                  .global_video.attributes.wistia_thumbnail_url
                              "
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- feedback video sendtart -->
                </div>
              </div>
              <div class="modal-btn">
                <div class="learn-btn">
                  <button
                    class="btn secondary"
                    @click="next"
                    :aria-label="!nextStatus ? 'Next' : 'Go To Dashboard'"
                    tabindex="0"
                  >
                    {{ !nextStatus ? "Next" : "Go To Dashboard" }}
                    <em class="icon-arrow-right"></em>
                  </button>
                </div>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
      </FocusLoop>
    </modal>
    <!-- feedback Task modal end -->
    <final-feedback
      :finalDebrief="finalDebrief"
      :enable_grading="enable_grading"
      :settings="settings"
      :disableReportBtn="disableReportBtn"
      :overall_grade_display_type="overall_grade_display_type"
      :overall_grade_label="overall_grade_label"
      :overall_grade_percentage="overall_grade_percentage"
    >
    </final-feedback>
    <modal
      name="feedbackInfo"
      aria-label="feedbackInfo"
      role="dialog"
      width="560"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-info-content">
          <button
            type="button"
            class="modal-close"
            @click="$modal.hide('feedbackInfo')"
            aria-label="close modal"
          >
            <em class="icon-cross"></em>
          </button>
          <div class="modal-body" v-if="infoModalValues.attributes">
            <h3>How {{ skillName }} is Assessed.</h3>
            <p
              v-if="
                infoModalValues.attributes.email_skill.eval_explanation != null
              "
            >
              {{ infoModalValues.attributes.email_skill.eval_explanation }}
            </p>
            <p
              v-if="
                infoModalValues.attributes.email_skill.eval_explanation == null &&
                infoModalValues.attributes.global_skill.eval_explanation != null
              "
            >
              {{ infoModalValues.attributes.global_skill.eval_explanation }}
            </p>
          </div>
        </div>
      </FocusLoop>
    </modal>

    <modal
      name="iterationInfo"
      aria-label="iterationInfo"
      role="dialog"
      width="560"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-info-content">
          <button
            type="button"
            class="modal-close"
            @click="$modal.hide('iterationInfo')"
            aria-label="close modal"
          >
            <em class="icon-cross"></em>
          </button>
          <div class="modal-body" v-if="emailfeedbackResponse.attributes">
            <h3>What is an iteration?</h3>
            {{ emailfeedbackResponse.attributes.iteration_explanation }}
          </div>
        </div>
      </FocusLoop>
    </modal>

    <modal
      name="iterationInfoShow"
      aria-label="iterationInfoShow"
      role="dialog"
      width="560"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-info-content">
          <button
            type="button"
            class="modal-close"
            @click="$modal.hide('iterationInfoShow')"
            aria-label="close modal"
          >
            <em class="icon-cross"></em>
          </button>
          <div class="modal-body" v-if="emailfeedbackResponse.attributes">
            <h3>What is an iteration?</h3>
            {{ emailfeedbackResponse.attributes.iteration_explanation }}
          </div>
        </div>
      </FocusLoop>
    </modal>

    <modal
      name="gatheringInformation"
      aria-label="gatheringInformation"
      role="dialog"
      :width="815"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div
          class="modal-content modal-gathering-content"
          v-if="interstitialContents.length > 0"
        >
          <div class="modal-body">
            <VuePerfectScrollbar
              class="modal-gather-content"
              :settings="settings"
            >
              <div
                class="gather-content-item"
                v-if="
                  this.selectedInterstitialContent.attributes.content_details
                    .type == 'text_learn_obj'
                "
              >
                <h3
                  v-html="this.selectedInterstitialContent.attributes.title"
                ></h3>
                <p
                  v-html="this.selectedInterstitialContent.attributes.description"
                ></p>
                <p
                  v-html="
                    this.selectedInterstitialContent.attributes.content_details
                      .attributes.title
                  "
                ></p>
              </div>
              <div
                class="gather-content-item"
                v-if="
                  selectedInterstitialContent.attributes.content_details.type ==
                  'video_learn_obj'
                "
              >
                <h3>{{ selectedInterstitialContent.attributes.title }}</h3>
                <p
                  class="mb-4"
                  v-html="selectedInterstitialContent.attributes.description"
                ></p>

                <div class="wistia_responsive_wrapper">
                  <iframe
                    :src="
                      preIframUrl +
                      selectedInterstitialContent.attributes.content_details
                        .attributes.global_video.attributes.wistia_code +
                      postIframUrl
                    "
                    title="Video.mp4"
                    class="wistia_embed"
                    name="wistia_embed"
                    allowtransparency="true"
                    scrolling="no"
                    allowfullscreen
                    mozallowfullscreen
                    webkitallowfullscreen
                    oallowfullscreen
                    msallowfullscreen
                    width="100%"
                    height="100%"
                  ></iframe>
                </div>
              </div>

              <div
                class="gather-content-item"
                v-if="
                  selectedInterstitialContent.attributes.content_details.type ==
                  'file_learn_obj'
                "
              >
                <h3 v-html="selectedInterstitialContent.attributes.title"></h3>
                <p
                  v-html="selectedInterstitialContent.attributes.description"
                ></p>
                <div class="suggestion-container">
                  <div class="suggestion-row mt-4">
                    <div class="suggestion-pdf">
                      <a
                        :href="
                          selectedInterstitialContent.attributes.content_details
                            .attributes.resource_url
                        "
                        target="_blank"
                      >
                        <span class="truncate"
                          >{{
                            selectedInterstitialContent.attributes.content_details
                              .attributes.resource_file_name
                          }}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="gather-content-item"
                v-if="
                  selectedInterstitialContent.attributes.content_details.type ==
                  'slide_learn_obj'
                "
              >
                <h3>{{ selectedInterstitialContent.attributes.title }}</h3>
                <p
                  class="mb-4"
                  v-html="selectedInterstitialContent.attributes.description"
                ></p>

                <carousel
                  :margin="0"
                  :nav="true"
                  :navText="[]"
                  :autoplay="false"
                  :autoHeight="false"
                  :items="1"
                  :dots="false"
                  :loop="true"
                >
                  <div
                    v-for="img in selectedInterstitialContent.attributes
                      .content_details.attributes.slider_images"
                    :key="img.id"
                  >
                    <div class="introduction-video">
                      <div class="introduction-intro-title">
                        {{ img.attributes.caption }}
                      </div>
                      <img
                        v-if="img.attributes.resource_url.large_version"
                        :src="img.attributes.resource_url.large_version"
                        alt=""
                        class="intro-slide-video"
                      />
                    </div>
                  </div>
                </carousel>
              </div>
            </VuePerfectScrollbar>
          </div>
        </div>
      </FocusLoop>
    </modal>

    <modal
      name="suggestedContentModal"
      aria-label="suggestedContentModal"
      role="dialog"
      :width="815"
      height="auto"
      :scrollable="true"
      @before-open="beforeOpen"
    >
      <FocusLoop :is-visible="isOpen">
        <div class="modal-content modal-gathering-content">
          <div class="modal-body">
            <div class="gather-content-item" v-if="type == 'video'">
              <h3>{{ title }}</h3>
              <p class="mb-4">{{ desc }}</p>
              <div class="wistia_responsive_wrapper">
                <iframe
                  :src="preIframUrl + videoCode + postIframUrl"
                  title="Video.mp4"
                  class="wistia_embed"
                  name="wistia_embed"
                  allowtransparency="true"
                  scrolling="no"
                  allowfullscreen
                  mozallowfullscreen
                  webkitallowfullscreen
                  oallowfullscreen
                  msallowfullscreen
                  width="100%"
                  height="100%"
                ></iframe>
              </div>
            </div>
            <div class="gather-content-item" v-if="type == 'slide'">
              <h3>{{ title }}</h3>
              <p class="mb-4">{{ desc }}</p>
              <carousel
                :margin="0"
                :nav="true"
                :navText="[]"
                :autoplay="false"
                :autoHeight="false"
                :items="1"
                :dots="false"
                :loop="true"
              >
                <div v-for="img in sliderImages" :key="img.id">
                  <div class="introduction-video">
                    <img
                      v-if="img.attributes.resource_url.large_version"
                      :src="img.attributes.resource_url.large_version"
                      :alt="img.attributes.global_resource.attributes.tag_list"
                      class="intro-slide-video"
                    />
                    <div class="introduction-intro-top">
                      <div class="introduction-intro-title">
                        {{ img.attributes.caption }}
                      </div>
                    </div>
                  </div>
                </div>
              </carousel>
            </div>
          </div>
        </div>
      </FocusLoop>
    </modal>
    <PdfViewer />
    <div class="chatSidebar" v-if="showLearnerMsg || chatMessages.length > 0">
      <button
        class="chatSidebar-btn"
        @click="openChatMsg"
        v-if="!showChat && card_type"
        aria-label="open chat message"
      >
        <img src="../assets/images/chatBig-icon.svg" alt="" />
        <span
          v-if="
            menus.length > 0 &&
            menus[key] &&
            menus[key].attributes.learner_messages_count > 0
          "
          >{{ menus[key].attributes.learner_messages_count }}</span
        >
      </button>
      <div class="chatSidebar-panel" v-if="showChat">
        <div class="chatSidebar-head">
          <span>Send Message to Instructor</span>
          <button @click="openChatMsg" aria-label="close chat message">
            <em class="icon-close"></em>
            <span class="visually-hidden">Close chat message</span>
          </button>
        </div>
        <div class="chatSidebar-content">
          <div
            class="chatSidebar-menu"
            v-for="msg in chatMessages"
            :key="msg.id"
          >
            <div class="chatSidebar-date" v-if="msg.attributes.created_date">
              <span>{{ msg.attributes.created_date }}</span>
            </div>
            <div
              :class="
                msg.attributes.is_instructor
                  ? 'chatSidebar-left'
                  : 'chatSidebar-right'
              "
            >
              <div class="chatSidebar-user" v-if="msg.attributes.is_instructor">
                <div class="chatSidebar-image">
                  <img
                    :src="msg.attributes.sender_image.thumbnail"
                    v-if="msg.attributes.sender_image"
                  />
                </div>
                {{ msg.attributes.sender_name }}
              </div>
              <div class="chatSidebar-box">{{ msg.attributes.content }}</div>
              <div class="chatSidebar-time">{{ msg.attributes.time_ago }}</div>
            </div>
          </div>
        </div>
        <div class="chatSidebar-footer">
          <textarea
            class="form-control"
            v-model="chatMessage"
            v-on:keyup.enter="sendChatMessages"
            placeholder="Type your Message here"
            aria-label="Type your messagee here"
          ></textarea>
          <button
            class="btn btn-ametros"
            :disabled="chatMessage.length > 0 ? false : true"
            @click="sendChatMessages"
          >
            Send
          </button>
        </div>
      </div>
    </div>
    <PdfModal />
    <csvModal />
    <final-feedback-llm-dialogic-modal />
  </div>
</template>
<!-- Lodash is already a part of the project but just want to check if it's actually being used -->
<!-- <script src="https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.15/lodash.min.js"></script> -->

<script>
import api from "../services/api";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { utilFunctionService } from "@/utils/utils.service";
import richText from "../components/richText";
import slide from "../components/slide";
import email from "../components/email";
import emailNoPlotpoint from "../components/emailNoPlotPoint.vue";
import Video from "../components/video";
import Pdf from "../components/pdf";
import dialogic from "../components/dialogic.vue";
import chat from "../components/chat.vue";
import submission from "../components/submission.vue";
import submissionEmail from "../components/submissionEmail.vue";
import coding from "../components/coding.vue";
import carousel from "vue-owl-carousel";
import PdfViewer from "../components/PdfViewer.vue";
import Axios from "axios";
import moment from "moment";
import finalFeedback from "../components/finalFeedback.vue";
import PdfModal from "@/components/pdfModal";
import learnSidebar from "../components/learnSidebar.vue";
import learningIntro from "../components/learningIntro.vue";
import debounce from "lodash/debounce";
import { mapGetters } from "vuex";
import loButtons from "../components/loButtons.vue";
import llmChat from "../components/llmChat.vue";
import llmDialogic from "../components/llmDialogic.vue";
import llmEmail from "../components/llmEmail.vue";
import finalFeedbackLlmDialogicModal from "../components/finalFeedbackLlmDialogicModal.vue";
import csv from "../components/csv.vue";
import csvModal from "../components/csvModal.vue";

export default {
  // This watch function needs to be a mixin
  watch: {
    titlePage: {
      immediate: true,
      handler() {
        document.title = this.titlePage;
      },
    },
  },
  name: "learning-component",
  components: {
    VuePerfectScrollbar,
    richText,
    slide,
    email,
    emailNoPlotpoint,
    carousel,
    Video,
    Pdf,
    dialogic,
    submission,
    submissionEmail,
    chat,
    PdfViewer,
    finalFeedback,
    coding,
    PdfModal,
    learnSidebar,
    learningIntro,
    loButtons,
    llmChat,
    finalFeedbackLlmDialogicModal,
    csv,
    csvModal,
    llmEmail,
    llmDialogic
  },
  computed: {
    ...mapGetters("user", {
      userDetails: "getUserDetails",
    }),
  },
  data() {
    return {
      disableReportBtn: false,
      settings: {
        maxScrollbarLength: 30,
      },
      titlePage: "",
      studentIntegrityClicked: false,
      enableIntegrity: false,
      integrityStudentAgree: false,
      enable_grading: false,
      overall_grade_display_type: null,
      overall_grade_label: null,
      overall_grade_percentage: null,
      awsAccess: "",
      awsSecret: "",
      externalApiName: "",
      externalApiDesc: "",
      externalApiloId: "",
      externalApiUserLearnObjId: "",
      externalApiModId: "",
      externalApiStatus: "begin",
      externalApiValidInfo: false,
      externalApiProgress: -100,
      externalApiCompleted: false,
      externalEvaluation: [],
      menus: [],
      questions: [],
      fullToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }]],
      simpleToolbar: [["bold", "italic", "underline"], [{ list: "ordered" }]],
      questionId: 0,
      userLoId: "",
      numericValue: "",
      rangeValue: "",
      longAnswer: "",
      intro: {},
      intros: {},
      preIframUrl: "https://fast.wistia.net/embed/iframe/",
      postIframUrl: "?videoFoam=true",
      id: this.$route.params.id,
      card_type: "",
      active_data: {},
      key: "",
      loId: "",
      card_data: {},
      global_video: {},
      image: [],
      captionImage: false,
      url: utilFunctionService.getImageUrl(),
      percentage: 0,
      showIntro: false,
      showIntegrity: false,
      nextStatus: false,
      leftQuizId: "",
      overallMessage: "",
      tallyMessage: "",
      results: [],
      resultStatus: false,
      quizName: "",
      quizDesc: "",
      quizEvaluationId: "",
      overAllAssesment: "",
      toCharacters: "",
      ccCharacters: "",
      emailSubject: "",
      emailDesc: "",
      interactiveEvaluationId: "",
      emailLists: [],
      user: {},
      interstitialContents: [],
      selectedInterstitialContent: {},
      nextIteration: false,
      learningObjectType: "",
      emailfeedbackResponse: "",
      overall_assmnt_item: "",
      hideAssessmentLabel: false,
      finalDebrief: {
        data: [],
      },
      skillName: "",
      infoModalValues: {},
      name: "",
      ootoResponseStatus: false,
      ootoCharacters: [],
      ootoNotice: "",
      showInterstitial: false,
      videoCode: "",
      type: "",
      sliderImages: [],
      title: "",
      desc: "",
      disableNextBtn: true,
      emailSendBtnText: "Send",
      user_roles: utilFunctionService.getUserRoles(),
      filterEmailIndex: {
        0: { msg: "", arr: [], sub: "" },
        1: { msg: "", arr: [], sub: "" },
        2: { msg: "", arr: [], sub: "" },
        3: { msg: "", arr: [], sub: "" },
        4: { msg: "", arr: [], sub: "" },
      },
      stickyNote: [],
      learningId: "",
      chatMessages: [],
      chatMessage: "",
      showChat: false,
      messageDate: "",
      milestones: [],
      holdOnMessage: "",
      count: 1,
      showLearnerMsg: false,
      quizEvalProgress: 0,
      quizQuesEvaluated: 0,
      introEmailStatus: false,
      introEmailSubject: "",
      introEmailBody: "",
      introAttachment: "",
      hideScore: false,
      pdfURL: "",
      loReset: "",
      hasCompletedModule: false,
      hasReviewedFeedback: false,
      brandImages: {},
      showCorrectAdaptiveFeedback: false,
      stateKey: 0,
      isOpen: false, 
      sso_authenticated: false,
      isMoatCustom: false,
      llmType: ""
    };
  },
  channels: {
    QuizEvaluationNotificationChannel: {
      received(data) {
        //console.log(data, "*** data in QuizEvaluationNotificationChannel");
        if (data.invoked_user_id == utilFunctionService.currentUserId()) {
          this.quizEvalProgress = data.progress;
          this.quizQuesEvaluated = data.evaluated_count;
          if (data.done && data.progress === 100) {
            this.hide("awaitResults");
            this.$cable.unsubscribe("QuizEvaluationNotificationChannel");
            this.getResult(this.quizEvaluationId);
          }
        }
      },
    },
  },
  methods: {
    toggleMenu() {
      document
        .querySelector(".learn-sidebar")
        .classList.toggle("learn-sidebar-show");
    },
    checkIntegrity(e, i) {
      if (e && !i) {
        this.getActive("I");
      }
    },
    externalapiConnect() {
      const fd = new FormData();
      fd.append("external_api_learn_obj_id", this.externalApiloId);
      fd.append("learn_mod_id", this.externalApiModId);
      fd.append("access_key_id", this.awsAccess);
      fd.append("secret_access_key", this.awsSecret);
      api
        .sendExternalApiInfo(fd)
        .then((res) => {
          this.externalApiValidInfo = res.data.valid_info;
          // console.log(this.externalApiValidInfo);
          if (this.externalApiValidInfo) {
            this.externalApiStatus = "connected";
          } else {
            this.show("awsNotConnect");
            this.externalApiStatus = "wrong";
          }
        })
        .catch();
    },
    externalApiEditKeys() {
      this.externalApiStatus = "begin";
    },
    externalapiSubmit() {
      this.show("awsSubmit");
    },
    externalapiSubmitConfirmed() {
      this.externalApiStatus = "feedback";
      this.externalApiProgress = -99;
      this.externalapiSubmitConfirmedModal();
      this.externalApiCompleted = false;
      this.externalApiProgress = 0;

      const fd = new FormData();
      fd.append("external_api_learn_obj_id", this.externalApiloId);
      fd.append("user_learn_obj_id", this.externalApiUserLearnObjId);
      fd.append("access_key_id", this.awsAccess);
      fd.append("secret_access_key", this.awsSecret);
      api
        .sendExternalApiEvaluation(fd)
        .then((res) => {
          for (
            let j = 0;
            j <
            res.data.data.attributes.user_external_api_debrief_evaluations
              .length;
            j++
          ) {
            const MultiRowResponses = {
              id: res.data.data.attributes
                .user_external_api_debrief_evaluations[j].id,
              assessmentLabel:
                res.data.data.attributes.user_external_api_debrief_evaluations[
                  j
                ].attributes.assessment_label,
              competencyCodeReceived:
                res.data.data.attributes.user_external_api_debrief_evaluations[
                  j
                ].attributes.competency_code_received,
              debriefReceived:
                res.data.data.attributes.user_external_api_debrief_evaluations[
                  j
                ].attributes.debrief_received,
              externalApiAssmntBlockId:
                res.data.data.attributes.user_external_api_debrief_evaluations[
                  j
                ].attributes.external_api_assmnt_block_id,
              externalApiAssmntBlockName:
                res.data.data.attributes.user_external_api_debrief_evaluations[
                  j
                ].attributes.external_api_assmnt_block_name,
              userExternalApiEvaluationId:
                res.data.data.attributes.user_external_api_debrief_evaluations[
                  j
                ].attributes.user_external_api_evaluation_id,
              assessmentIconUrl:
                res.data.data.attributes.user_external_api_debrief_evaluations[
                  j
                ].attributes.assessment_icon_url,
            };
            this.externalEvaluation.push(MultiRowResponses);
          }
          this.externalApiProgress = 50;
          let questionMultiRowsSorted = [];
          let rowsSorting = [];
          for (let i = 0; i < this.externalEvaluation.length; i++) {
            rowsSorting.push(
              parseInt(this.externalEvaluation[i].externalApiAssmntBlockId)
            );
          }
          rowsSorting.sort((a, b) => a - b);
          for (let i = 0; i < rowsSorting.length; i++) {
            for (let j = 0; j < this.externalEvaluation.length; j++) {
              if (
                parseInt(
                  this.externalEvaluation[j].externalApiAssmntBlockId
                ) === rowsSorting[i]
              ) {
                questionMultiRowsSorted.push(this.externalEvaluation[j]);
              }
            }
          }
          this.externalEvaluation = [];
          for (let j = 0; j < questionMultiRowsSorted.length; j++) {
            const MultiRowResponses = {
              id: questionMultiRowsSorted[j].id,
              assessmentLabel: questionMultiRowsSorted[j].assessmentLabel,
              competencyCodeReceived:
                questionMultiRowsSorted[j].competencyCodeReceived,
              debriefReceived: questionMultiRowsSorted[j].debriefReceived,
              externalApiAssmntBlockId:
                questionMultiRowsSorted[j].externalApiAssmntBlockId,
              externalApiAssmntBlockName:
                questionMultiRowsSorted[j].externalApiAssmntBlockName,
              userExternalApiEvaluationId:
                questionMultiRowsSorted[j].userExternalApiEvaluationId,
              assessmentIconUrl: questionMultiRowsSorted[j].assessmentIconUrl,
            };
            this.externalEvaluation.push(MultiRowResponses);
          }
          this.externalApiProgress = 99;
        })
        .catch();
      this.externalApiCompleted = true;
    },
    externalapiSubmitConfirmedModal() {
      this.hide("awsSubmit");
      this.show("awsSubmitConfirmed");
    },
    getExternalFeedback() {
      this.hide("awsSubmitConfirmed");
      this.externalApiStatus = "feedback";
      // this.show("awsFeedback")
    },
    getExternalShowFeedback() {
      this.show("awsFeedback");
    },

    beforeQuizOpen() {
      this.isOpen = true;
    },

    beforeOpen(event) {
      //console.log("*** hit beforeOpen");
      //console.log(event, "*** event in beforeOpen");
      if (event.name === 'holdingTask') {
        this.isOpen = true;
      } else {
        this.type = event.params.type;
        this.title = event.params.title.title;
        this.desc = event.params.title.description;
        this.isOpen = true;
        if (event.params.type === "video") {
          this.videoCode = event.params.data;
        } else {
          this.sliderImages = event.params.data;
        }
      }
    },
    scrollToElement(key) {
      var menuContainer = this.$el.getElementsByClassName(
        "learn-sidebar-items"
      )[0];
      var activeMenuItem =
        this.$el.getElementsByClassName("learn-sidebar-item")[key];
      var topPos = activeMenuItem.offsetTop;

      menuContainer.scrollTop = topPos - 230;
    },
    scrollToContentBottom: debounce(function () {
      this.$nextTick(() => {
        if (this.$refs.dialogicComponent.length > 0) {
          // This buncha weirdness scrolls the most recent character response into view after a learner hits Send on a dialogic interaction
          let learnObjInfoLength =
            this.$el.getElementsByClassName("learn-object-info").length;
          let realBottom =
            this.$el.getElementsByClassName("learn-object-info")[
              learnObjInfoLength - 1
            ];
          // console.log(learnObjInfoLength, "*** learnObjInfoLength");
          // console.log(realBottom, "*** realBottom");
          realBottom.scrollIntoView({
            block: "start",
            inline: "nearest",
            behaviour: "smooth",
          });
        }
      });
    }, 3400),

    emailBody() {
      if (this.emailDesc.length < 75) {
        this.disableNextBtn = true;
      } else {
        this.disableNextBtn = false;
      }
    },
    autosaveEmail: debounce(function () {
      this.saveDraft();
    }, 2000),
    saveDraft() {
      //// console.log(`Saving Draft...${this.emailSubject}, ${this.emailDesc}`)
      const fb = new FormData();
      fb.append("user_email_iteration[email]", this.emailDesc);
      fb.append("user_email_iteration[is_draft]", true);
      fb.append("email_subject", this.emailSubject);
      if (this.emailDesc) {
        api.sendEmailResponse(this.interactiveEvaluationId, fb);
      }
    },
    modalInfoModal(feedback, name, values) {
      this.skillName = name;
      this.infoModalValues = values;
      this.show(feedback);
    },
    show(modal) {
      this.$modal.show(modal);
    },
    hide(modal) {
      this.$modal.hide(modal);
    },
    selectedInterstitial(data) {
      this.selectedInterstitialContent = data;
    },
    downloadPdf(link, name) {
      Axios.get(link, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", name);
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    // downloadItem() {
    //   if (this.finalDebrief && this.finalDebrief.evaluation_url) {
    //     Axios.get(this.finalDebrief.evaluation_url, { responseType: "blob" })
    //       .then((response) => {
    //         const blob = new Blob([response.data], { type: "application/pdf" });
    //         const link = document.createElement("a");
    //         link.href = URL.createObjectURL(blob);
    //         link.setAttribute("download", "Ametros-report.pdf");
    //         link.click();
    //         URL.revokeObjectURL(link.href);
    //       })
    //       .catch();
    //   }
    // },
    iterationInfo() {
      this.$modal.show("iterationInfoShow");
    },
    sendEmail() {
      const fb = new FormData();
      fb.append("user_email_iteration[email]", this.emailDesc);
      fb.append("email_subject", this.emailSubject);
      this.disableNextBtn = true;
      this.emailSendBtnText = "Sending...";
      utilFunctionService.showLoader();
      api.sendEmailResponse(this.interactiveEvaluationId, fb).then(() => {
        this.emailDesc = "";
        this.getEmailLists();
        setTimeout(() => {
          utilFunctionService.hideLoader();
          this.emailSendBtnText = "Send";
        }, 1000);
      });
    },
    getPercent(i) {
      return i + 1;
    },
    selectMcqOption(i, question, answer) {
      this.questions[i].answer = "";
      this.$mount();
      question.answer = answer;
      this.disableNextBtn = false;
    },
    quizAnswer(answer) {

      if (answer.trim().length === 0) {
        this.disableNextBtn = true;
      } else {
        this.disableNextBtn = false;
      }
    },
    quizAnswerMultiRow() {
      this.disableNextBtn = false;
    },
    getBtnStatus(a) {
      if (a.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    submitQuiz() {
      let i = this.questions.length - 1;
      let question = this.questions[i];
      this.nextQuestion(
        question.id,
        question.answer,
        i,
        question,
        "result",
        question.attributes.question_type
      );
    },
    resetQuiz() {
      utilFunctionService.showLoader();
      api.resetQuizs(this.quizEvaluationId).then(() => {
        utilFunctionService.hideLoader();
        this.getQuizQuestions(this.active_data, this.userLoId);
      });
    },
    getResult(quizId) {
      utilFunctionService.showLoader();
      api.getQuizResponse(quizId).then((res) => {
        utilFunctionService.hideLoader();
        this.resultStatus = true;
        this.overallMessage = res.data.data.attributes.overall_message;
        this.tallyMessage = res.data.data.attributes.tally_message;
        this.hideScore = res.data.data.attributes.hide_score;
        this.overAllAssesment = res.data.data.attributes.overall_assmnt_item;
        this.hasReviewedFeedback = true;
        api
          .setHasReviewedFeedback(this.id, this.active_data.id)
          .then(() => {
            this.hasReviewedFeedback = true;
          })
          .catch(() => {
            console.log("");
          });
        if (this.overAllAssesment && this.overAllAssesment.attributes) {
          this.overAllAssesment = this.overAllAssesment.attributes;
        }
        this.results = res.data.data.attributes.quiz_responses;
        this.show("seeResult");
      });
    },
    loadResults() {
      this.$cable.subscribe({
        channel: "QuizEvaluationNotificationChannel",
        room: "public",
      });
      this.show("awaitResults");
    },
    sideMenu() {
      utilFunctionService.showLoader();
      api
        .getMenu(
          this.id,
          decodeURI(moment(new Date()).format("LLL")),
          parseInt(this.userDetails.id)
        )
        .then((res) => {
          utilFunctionService.hideLoader();
          this.menus = res.data.user_learn_objs.data;
          this.intros = res.data.intro_data;
          // change webpage title
          this.titlePage = `Ametros Learning - ${this.intros.attributes.name} - Introduction`;
          this.brandImages = this.intros.attributes.brand_images ? this.intros.attributes.brand_images : [];
          // integrity
          this.enableIntegrity =
            this.intros.attributes.enable_academic_integrity;
          api.getIntegrityAccept(this.id).then((res) => {
            this.integrityStudentAgree =
              res.data.data.attributes.academic_integrity_agree;
            this.studentIntegrityClicked = this.integrityStudentAgree;
            this.checkIntegrity(
              this.enableIntegrity,
              this.integrityStudentAgree
            );
          });
          this.milestones = res.data.milestones;
          this.showLearnerMsg = res.data.learner_org_msg;
          this.percentage =
            this.menus[0].attributes.user_section.attributes.complete_percentage;
          if (this.percentage > 100) this.percentage = 100;
          let status = "";
          this.menus.forEach((element, key) => {
            this.menus[key]["on_hold"] = false;
            this.menus[key]["on_hold_message"] = "";
            this.menus[key]["completed_by"] = "";
            this.menus[key]["milestoneIndex"] = "";
            if (element.attributes.complete) {
              status = key + 1;
            }
            if (
              element.attributes.complete === true &&
              key === this.menus.length - 1
            ) {
              this.hasCompletedModule = true;
            }
          });

          if (this.milestones.length) {
            this.milestones.forEach((x) => {
              let index = this.menus.findIndex(
                (section) =>
                  parseInt(section.attributes.learning_object_id) ===
                  parseInt(x.attributes.learning_object_id)
              );
              if (index !== -1) {
                this.menus[index]["on_hold"] = x.attributes["on_hold?"];
                this.menus[index]["completed_by"] = x.attributes.completed_by;
                this.menus[index]["on_hold_message"] =
                  x.attributes.on_hold_message;
              }
            });
            this.menus.forEach((x, i) => {
              if (x.completed_by) {
                this.menus[i]["milestoneIndex"] = this.count++;
              }
            });
          }
          if (this.$route.params.loId) {
            const loId = this.$route.params.loId;
            const id = this.menus.findIndex((x) => x.id === loId);
            this.key = id;
            this.getMenuDetails(loId, id);
          } else {
            if (status && !this.key) {
              this.showIntro = false;
              if (status === this.menus.length) {
                this.getActive("");
              } else {
                this.getActive(status);
              }
            } else {
              this.getActive(this.key);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          const invalidUser = error.response.data.invalidUser;
          utilFunctionService.hideLoader();
          if (invalidUser === true) {
            utilFunctionService.showerr(
              "This user does not have access to this module"
            );
            this.$router.push({ name: "dashboard" });
          } else {
            if (this.sso_authenticated) {
              utilFunctionService.showerr(
                "Session has expired."
              );
              this.$store.dispatch("user/logout");
              this.$router.push({ name: "lti-logout" });
            } else {
              utilFunctionService.showerr(
                "Signature has expired.Please login again"
              );
              utilFunctionService.removeLocalStorageService("learner");
              this.$router.push({ name: "login" });
            }
          }
        });
    },
    getMenuDetails(module_id, key) {
      api
        .getMenuDetails(this.id, module_id, this.active_data.id)
        .then((res) => {
          let learnContentAreaTop =
            document.getElementById("learn-content-area");
          learnContentAreaTop.scrollTo(0, 0);
          // change page title
          console.log(res.data.data, "*** res.data.data in getMenuDetails");
          this.titlePage = `Ametros Learning - ${this.intros.attributes.name} - ${res.data.data.attributes.learning_object.attributes.name}`;
          this.card_type = "";
          this.active_data = {};
          this.intro = "";
          this.nextStatus = false;
          this.active_data = res.data.data;
           // The following is breaking retries
          // if (key + 1 === this.menus.length) {
          //   this.nextStatus = true;
          // }
          // if (
          //   this.active_data.attributes.complete === false &&
          //   this.active_data.attributes.user_learn_obj_reset.length > 0
          // ) {
          //   this.loReset = true;
          // } else {
          //   this.loReset = false;
          // }
          this.card_type =
            this.active_data.attributes.learning_object.attributes.card_type;
          this.learningObjectType =
            this.active_data.attributes.learning_object.attributes.learning_object_type;
          this.intro = this.active_data.attributes.learning_object.attributes;
          this.loId = this.active_data.attributes.learning_object.id;
          this.quizName =
            this.active_data.attributes.learning_object.attributes.name;
          this.quizDesc =
            this.active_data.attributes.learning_object.attributes.description;
          this.showCorrectAdaptiveFeedback =
            this.active_data.attributes.learning_object.attributes.card_detail.attributes.show_correct_adaptive_feedback;
          this.externalApiName =
            this.active_data.attributes.learning_object.attributes.name;
          this.externalApiDesc =
            this.active_data.attributes.learning_object.attributes.description;
          this.externalApiloId =
            this.active_data.attributes.learning_object.attributes.card_detail.id;
          this.externalApiUserLearnObjId = this.active_data.id;
          this.externalApiModId =
            this.active_data.attributes.learning_object.attributes.learn_mod_id;
          let attr = this.active_data.attributes;
          this.stickyNote = attr.sticky_note ? [attr.sticky_note] : [];
          let loSections =
            attr.user_section.attributes.learning_object_sections;
          this.learningId = attr.learning_object_id;
          for (let i = 0; i < loSections.length; i++) {
            if (
              loSections[i].attributes.learning_object_id === this.learningId
            ) {
              this.stickyNote.push(loSections[i].attributes.sticky_note);
            }
          }
          if (this.card_type === "video") {
            this.global_video = this.intro.card_detail.attributes.global_video;
          }
          if (this.card_type === "slide") {
            this.image = [];
            this.image = this.intro.card_detail.attributes.slider_images;
            this.captionImage = this.intro.card_detail.attributes.has_caption;
          }
          if (this.card_type === "quiz") {
            this.hasReviewedFeedback =
              this.active_data.attributes.has_reviewed_feedback;
            this.getQuizQuestions(this.active_data, module_id);
          }
          if (this.card_type === "email") {
            this.toCharacters =
              this.active_data.attributes.learning_object.attributes.card_detail.attributes.to_characters;
            this.ccCharacters =
              this.active_data.attributes.learning_object.attributes.card_detail.attributes.cc_characters;
            this.interstitialContents =
              this.active_data.attributes.learning_object.attributes.card_detail.attributes.interstitial_contents;
            this.selectedInterstitialContent = this.interstitialContents[0];
            this.interactiveEvaluationId =
              this.active_data.attributes.current_evaluation_id;
            this.user =
              this.active_data.attributes.user_section.attributes.user;
            if (this.learningObjectType != "plot_point") {
              this.getEmailLists();
              this.getDraftEmail();
            }
          }
          if (this.card_type === "llm") {
            this.llmType = this.active_data.attributes.learning_object.attributes.learning_object_type;  
          }
          if (this.card_type === "external_api") {
            // console.log("*** hit if this.card_type is external_api");
            this.externalEvaluation = [];
            this.getExternalApiEvaluation();
          }
          if (
            document
              .querySelector(".learn-sidebar")
              .classList.contains("learn-sidebar-show")
          ) {
            this.toggleMenu();
          }
          this.stateKey = this.stateKey + 1;
          this.getChatMessages();
          this.scrollToElement(key);
        })
        .catch((error) => {
          if (error.response && error.response.status == 401) {
            
              if (this.sso_authenticated) {
                utilFunctionService.showerr(
                "Session has expired."
                );
                this.$store.dispatch("user/logout");
                this.$router.push({ name: "lti-logout" });
              } else {
                utilFunctionService.showerr(
                  "Signature has expired.Please login again"
                );
                utilFunctionService.removeLocalStorageService("learner");
                this.$router.push({ name: "login" });
              }
          }
        });
    },
    getExternalApiEvaluation() {
      // console.log("*** hit getexternalApiEvaluation");
      api
        .getExternalApiEvaluation(this.externalApiUserLearnObjId)
        .then((res) => {
          // console.log(res, "*** res");
          if (res.data.data) {
            for (
              let j = 0;
              j <
              res.data.data.attributes.user_external_api_debrief_evaluations
                .length;
              j++
            ) {
              const MultiRowResponses = {
                id: res.data.data.attributes
                  .user_external_api_debrief_evaluations[j].id,
                assessmentLabel:
                  res.data.data.attributes
                    .user_external_api_debrief_evaluations[j].attributes
                    .assessment_label,
                competencyCodeReceived:
                  res.data.data.attributes
                    .user_external_api_debrief_evaluations[j].attributes
                    .competency_code_received,
                debriefReceived:
                  res.data.data.attributes
                    .user_external_api_debrief_evaluations[j].attributes
                    .debrief_received,
                externalApiAssmntBlockId:
                  res.data.data.attributes
                    .user_external_api_debrief_evaluations[j].attributes
                    .external_api_assmnt_block_id,
                externalApiAssmntBlockName:
                  res.data.data.attributes
                    .user_external_api_debrief_evaluations[j].attributes
                    .external_api_assmnt_block_name,
                userExternalApiEvaluationId:
                  res.data.data.attributes
                    .user_external_api_debrief_evaluations[j].attributes
                    .user_external_api_evaluation_id,
                assessmentIconUrl:
                  res.data.data.attributes
                    .user_external_api_debrief_evaluations[j].attributes
                    .assessment_icon_url,
              };
              this.externalEvaluation.push(MultiRowResponses);
            }
          }
          if (this.externalEvaluation.length > 0) {
            this.externalApiStatus = "feedback";
          } else {
            this.externalApiStatus = "begin";
            this.awsAccess = "";
            this.awsSecret = "";
          }
        })
        .catch();
    },
    getEmailLists() {
      api
        .getEmailList(this.interactiveEvaluationId)
        .then((res) => {
          this.ootoCharacters = [];
          this.emailLists = res.data.data.reverse();
          if (this.emailLists.length > 0) {
            this.nextIteration =
              this.emailLists[
                this.emailLists.length - 1
              ].attributes.next_iteration_required;
          } else {
            this.nextIteration = false;
          }
          this.emailLists.forEach((z, i) => {
            let filterEmailList = [];
            z.attributes.responses.forEach((x) => {
              let a = z.attributes.responses.filter((o) => {
                if (
                  x.attributes.character.attributes.character_id ==
                  o.attributes.character.attributes.character_id
                ) {
                  return o;
                }
              });

              if (a.length > 1) {
                let q = filterEmailList.find(
                  (x) =>
                    a[0].attributes.character.attributes.character_id ==
                    x.attributes.character.attributes.character_id
                );
                if (q) {
                  return;
                }
                let ootoRes = a.find(
                  (e) => e.attributes.is_ooto_response == true
                );
                filterEmailList.push(ootoRes);
                let qaFixedRes = a.find((e) => e.attributes.qa_fixed == true);
                if (qaFixedRes) {
                  filterEmailList.push(qaFixedRes);
                }
                if (ootoRes && !qaFixedRes) {
                  this.ootoResponseStatus = true;
                  this.ootoCharacters.push(
                    a[0].attributes.character.attributes.char_first_name
                  );
                }
                if (ootoRes) {
                  this.showInterstitial = true;
                }
              } else {
                if (a[0].attributes.is_ooto_response) {
                  this.ootoResponseStatus = true;
                  this.showInterstitial = true;
                  this.ootoCharacters.push(
                    a[0].attributes.character.attributes.char_first_name
                  );
                }
                filterEmailList.push(a[0]);
              }
            });
            this.filterEmailIndex[i] = {
              arr: filterEmailList,
              sub: z.attributes.email_subject,
              msg: z.attributes.email,
            };
          });
          if (this.ootoResponseStatus == true) {
            this.setOotoNotice();
          }
        })
        .catch(() => {
          utilFunctionService.hideLoader();
        });
    },
    getDraftEmail() {
      api.getDraftEmail(this.interactiveEvaluationId).then((res) => {
        if (res.data && res.data.draft_state) {
          this.emailDesc = res.data.email;
          this.emailSubject = res.data.subject;
        }
      });
    },
    setOotoNotice() {
      if (this.ootoCharacters.length > 1) {
        let length = this.ootoCharacters.length;
        let allCharacters = this.ootoCharacters.slice(0, length - 1).join(", ");
        allCharacters += ` and ${this.ootoCharacters[length - 1]}`;
        this.ootoNotice = `Note: You will be able to reply once ${allCharacters} have responded in depth. You will receive a notification by email when they have responded.`;
      } else if (this.ootoCharacters.length === 1) {
        let char = this.ootoCharacters[0];
        this.ootoNotice = `Note: You will be able to reply once ${char} has responded in depth. You will receive a notification by email when ${char} has responded.`;
      }
    },
    getQuizQuestions(res, userLoId) {
      this.userLoId = userLoId;
      this.questionId = 0;
      api
        .quizQuestions(
          res.attributes.learning_object.attributes.card_detail.id,
          userLoId
        )
        .then((x) => {
          this.questions = x.data.questions.data;
          this.questions.map((x) => {
            x.answer = "";
            x.status = false;
            return x;
          });
          if (
            typeof x.data.responses.data === "object" &&
            x.data.responses.data.id
          ) {
            x.data.responses.data[0] = x.data.responses.data;
          }
          if (
            x.data.responses.data[0] &&
            x.data.responses.data[0].attributes.quiz_responses.length > 0
          ) {
            x.data.responses.data[0].attributes.quiz_responses.forEach(
              (element) => {
                const index = this.questions.findIndex((elem) => {
                  return (
                    parseInt(elem.id) ===
                    parseInt(element.attributes.quiz_question_id)
                  );
                });
                if (index !== -1) {
                  this.questions[index].complete = true;
                  this.questions[index].status = true;
                  this.questions[index].quizEvaluationId =
                    x.data.responses.data[0].id;
                  this.questions[index].quizId = element.id;
                  this.quizEvaluationId = x.data.responses.data[0].id;
                  if (
                    this.questions[index].attributes.question_type ==
                    "multiple_choice"
                  ) {
                    this.questions[index].answer =
                      element.attributes.learner_chosed_option.id;
                    this.questions[index].mcqStatus =
                      element.attributes.learner_chosed_option.id;
                  } else if (
                    this.questions[index].attributes.question_type ==
                    "ai_text_rows"
                  ) {
                    this.quizAnswerMultiRow();
                    this.questions[index].answer = [];
                    if (element.attributes.text_row_responses.length > 0) {
                      for (
                        let j = 0;
                        j < element.attributes.text_row_responses.length;
                        j++
                      ) {
                        const MultiRowResponses = {
                          responseMultiRow:
                            element.attributes.text_row_responses[j].attributes
                              .response,
                          id: element.attributes.text_row_responses[j].id,
                          order:
                            this.questions[index].attributes.text_rows[j]
                              .attributes.order,
                        };
                        this.questions[index].answer.push(MultiRowResponses);
                      }
                      let questionMultiRowsSorted = [];
                      let rowsSorting = [];
                      for (
                        let i = 0;
                        i < this.questions[index].answer.length;
                        i++
                      ) {
                        rowsSorting.push(
                          parseInt(this.questions[index].answer[i].order)
                        );
                      }
                      rowsSorting.sort((a, b) => a - b);
                      for (let i = 0; i < rowsSorting.length; i++) {
                        for (
                          let j = 0;
                          j < this.questions[index].answer.length;
                          j++
                        ) {
                          if (
                            parseInt(this.questions[index].answer[j].order) ===
                            rowsSorting[i]
                          ) {
                            questionMultiRowsSorted.push(
                              this.questions[index].answer[j]
                            );
                          }
                        }
                      }
                      this.questions[index].answer = [];
                      for (let j = 0; j < questionMultiRowsSorted.length; j++) {
                        const MultiRowResponses = {
                          responseMultiRow:
                            questionMultiRowsSorted[j].responseMultiRow,
                          id: questionMultiRowsSorted[j].id,
                          order: questionMultiRowsSorted[j].order,
                        };
                        this.questions[index].answer.push(MultiRowResponses);
                      }
                    } else {
                      for (
                        let j = 0;
                        j < this.questions[index].attributes.text_rows.length;
                        j++
                      ) {
                        const MultiRowResponses = {
                          responseMultiRow: "",
                        };
                        this.questions[index].answer.push(MultiRowResponses);
                      }
                    }
                    //// console.log(this.questions[index])
                  } else {
                    this.questions[index].answer =
                      element.attributes.learner_answer;
                  }
                }

                if (
                  x.data.responses.data[0].attributes.quiz_responses.length ==
                  this.questions.length
                ) {
                  this.leftQuizId =
                    x.data.questions.data[
                      x.data.responses.data[0].attributes.quiz_responses
                        .length - 1
                    ].id;
                  this.resultStatus = true;
                  this.questionId = this.questions.length - 1;
                } else {
                  this.leftQuizId =
                    x.data.questions.data[
                      x.data.responses.data[0].attributes.quiz_responses.length
                    ].id;
                  this.resultStatus = false;
                  this.questionId =
                    x.data.responses.data[0].attributes.quiz_responses.length;
                }
              }
            );

            for (let i = 0; i < this.questions.length; i++) {
              if (
                this.questions[i].attributes.question_type == "ai_text_rows" &&
                this.questions[i].answer.length === 0
              ) {
                this.quizAnswerMultiRow();
                this.questions[i].answer = [];
                for (
                  let j = 0;
                  j < this.questions[i].attributes.text_rows.length;
                  j++
                ) {
                  const MultiRowResponses = {
                    responseMultiRow: "",
                    order:
                      this.questions[i].attributes.text_rows[j].attributes
                        .order,
                  };
                  this.questions[i].answer.push(MultiRowResponses);
                }

                let questionMultiRowsSorted = [];
                let rowsSorting = [];
                for (let i2 = 0; i2 < this.questions[i].answer.length; i2++) {
                  rowsSorting.push(
                    parseInt(this.questions[i].answer[i2].order)
                  );
                }
                rowsSorting.sort((a, b) => a - b);
                for (let i2 = 0; i2 < rowsSorting.length; i2++) {
                  for (let j = 0; j < this.questions[i].answer.length; j++) {
                    if (
                      parseInt(this.questions[i].answer[j].order) ===
                      rowsSorting[i2]
                    ) {
                      questionMultiRowsSorted.push(this.questions[i].answer[j]);
                    }
                  }
                }
                this.questions[i].answer = [];
                for (let j = 0; j < questionMultiRowsSorted.length; j++) {
                  const MultiRowResponses = {
                    responseMultiRow:
                      questionMultiRowsSorted[j].responseMultiRow,
                    id: questionMultiRowsSorted[j].id,
                    order: questionMultiRowsSorted[j].order,
                  };
                  this.questions[i].answer.push(MultiRowResponses);
                }
                // console.log(this.questions[i].answer)
              }
            }
          } else {
            this.resultStatus = false;

            for (let i = 0; i < this.questions.length; i++) {
              if (
                this.questions[i].attributes.question_type == "ai_text_rows"
              ) {
                this.quizAnswerMultiRow();
                this.questions[i].answer = [];
                for (
                  let j = 0;
                  j < this.questions[i].attributes.text_rows.length;
                  j++
                ) {
                  const MultiRowResponses = {
                    responseMultiRow: "",
                    order:
                      this.questions[i].attributes.text_rows[j].attributes
                        .order,
                  };
                  this.questions[i].answer.push(MultiRowResponses);
                }
                let questionMultiRowsSorted = [];
                let rowsSorting = [];
                for (let i2 = 0; i2 < this.questions[i].answer.length; i2++) {
                  rowsSorting.push(
                    parseInt(this.questions[i].answer[i2].order)
                  );
                }
                rowsSorting.sort((a, b) => a - b);
                for (let i2 = 0; i2 < rowsSorting.length; i2++) {
                  for (let j = 0; j < this.questions[i].answer.length; j++) {
                    if (
                      parseInt(this.questions[i].answer[j].order) ===
                      rowsSorting[i2]
                    ) {
                      questionMultiRowsSorted.push(this.questions[i].answer[j]);
                    }
                  }
                }
                this.questions[i].answer = [];
                for (let j = 0; j < questionMultiRowsSorted.length; j++) {
                  const MultiRowResponses = {
                    responseMultiRow:
                      questionMultiRowsSorted[j].responseMultiRow,
                    id: questionMultiRowsSorted[j].id,
                    order: questionMultiRowsSorted[j].order,
                  };
                  this.questions[i].answer.push(MultiRowResponses);
                }
                //// console.log(this.questions[i].answer)
              }
            }
          }
        });
    },
    nextQuestion(id, answer, i, question, type, quizType) {
      if (quizType === "ai_text_rows") {
        const quizId = id;
        this.leftQuizId = "";
        if (type == "next") {
          this.questionId = ++i;
        } else {
          i = i + 1;
        }
        if (!this.resultStatus) {
          if (question.status) {
            const _fb = new FormData();
            _fb.append("quiz_evaluation[user_learn_obj_id]", this.userLoId);
            _fb.append(
              "quiz_evaluation[quiz_responses_attributes][][id]",
              question.quizId
            );
            _fb.append(
              "quiz_evaluation[quiz_responses_attributes][][quiz_question_id]",
              quizId
            );
            for (let j = 0; j < this.questions[i - 1].answer.length; j++) {
              _fb.append(
                `quiz_evaluation[quiz_responses_attributes][][text_row_responses_attributes][${j}][id]`,
                this.questions[i - 1].answer[j].id
              );
              _fb.append(
                `quiz_evaluation[quiz_responses_attributes][][text_row_responses_attributes][${j}][response]`,
                this.questions[i - 1].answer[j].responseMultiRow
              );
              _fb.append(
                `quiz_evaluation[quiz_responses_attributes][][text_row_responses_attributes][${j}][text_row_id]`,
                this.questions[i - 1].attributes.text_rows[j].id
              );
            }
            api
              .patchQuizResponse(_fb, question.quizEvaluationId)
              .then((x) => {
                question.quizEvaluationId = x.data.data.id;
                this.quizEvaluationId = x.data.data.id;
                if (type == "result") {
                  utilFunctionService.showSuccess(
                    "Quiz submitted successfully"
                  );
                  this.loadResults(question.quizEvaluationId);
                }
              })
              .catch();
          } else {
            const _fb = new FormData();
            _fb.append("quiz_evaluation[user_learn_obj_id]", this.userLoId);
            _fb.append(
              "quiz_evaluation[quiz_responses_attributes][][quiz_question_id]",
              quizId
            );

            for (let j = 0; j < this.questions[i - 1].answer.length; j++) {
              _fb.append(
                `quiz_evaluation[quiz_responses_attributes][][text_row_responses_attributes][${j}][response]`,
                this.questions[i - 1].answer[j].responseMultiRow
              );
              _fb.append(
                `quiz_evaluation[quiz_responses_attributes][][text_row_responses_attributes][${j}][text_row_id]`,
                this.questions[i - 1].attributes.text_rows[j].id
              );
            }
            api
              .postQuizResponse(_fb)
              .then((x) => {
                question.status = true;
                question.quizEvaluationId = x.data.data.id;
                this.quizEvaluationId = x.data.data.id;
                const l = x.data.data.attributes.quiz_responses.length;
                question.quizId =
                  x.data.data.attributes.quiz_responses[l - 1].id;
                if (type == "result") {
                  this.loadResults(question.quizEvaluationId);
                }
              })
              .catch();
          }
        } else {
          this.getResult(question.quizEvaluationId);
        }
      } else {
        if (answer.length == 0) {
          return false;
        } else {
          const quizId = id;
          this.leftQuizId = "";
          if (type == "next") {
            this.questionId = ++i;
          }
          if (!this.resultStatus) {
            if (question.status) {
              const _fb = new FormData();
              _fb.append("quiz_evaluation[user_learn_obj_id]", this.userLoId);
              _fb.append(
                "quiz_evaluation[quiz_responses_attributes][][id]",
                question.quizId
              );
              _fb.append(
                "quiz_evaluation[quiz_responses_attributes][][quiz_question_id]",
                quizId
              );
              if (quizType == "multiple_choice") {
                _fb.append(
                  "quiz_evaluation[quiz_responses_attributes][][mcq_response_id]",
                  answer
                );
              } else {
                _fb.append(
                  "quiz_evaluation[quiz_responses_attributes][][response]",
                  answer
                );
              }
              api
                .patchQuizResponse(_fb, question.quizEvaluationId)
                .then((x) => {
                  question.quizEvaluationId = x.data.data.id;
                  this.quizEvaluationId = x.data.data.id;
                  if (type == "result") {
                    this.loadResults(question.quizEvaluationId);
                  }
                });
            } else {
              const _fb = new FormData();
              _fb.append("quiz_evaluation[user_learn_obj_id]", this.userLoId);
              _fb.append(
                "quiz_evaluation[quiz_responses_attributes][][quiz_question_id]",
                quizId
              );
              if (quizType == "multiple_choice") {
                _fb.append(
                  "quiz_evaluation[quiz_responses_attributes][][mcq_response_id]",
                  answer
                );
              } else {
                _fb.append(
                  "quiz_evaluation[quiz_responses_attributes][][response]",
                  answer
                );
              }
              api.postQuizResponse(_fb).then((x) => {
                question.status = true;
                question.quizEvaluationId = x.data.data.id;
                this.quizEvaluationId = x.data.data.id;
                const l = x.data.data.attributes.quiz_responses.length;
                question.quizId =
                  x.data.data.attributes.quiz_responses[l - 1].id;
                if (type == "result") {
                  this.loadResults(question.quizEvaluationId);
                }
              });
            }
          } else {
            this.hasReviewedFeedback = true;
            api
              .setHasReviewedFeedback(this.id, this.active_data.id)
              .then(() => {
                this.hasReviewedFeedback = true;
              })
              .catch(() => {
                console.log("");
              });
            this.getResult(question.quizEvaluationId);
          }
        }
      }
    },
    prevQuestion(id, i) {
      this.questionId = --i;
      this.leftQuizId = "";
    },
    getActive(key) {
      this.hide("");
      console.log("getActive key", key);
      if (!key && key === "") {
        this.titlePage = `Ametros Learning - ${this.intros.attributes.name} - Introduction`;
        this.active_data = {};
        this.card_type = "";
        this.showIntro = true;
        this.showIntegrity = false;
      } else if (key === "I") {
        this.titlePage = `Ametros Learning - ${this.intros.attributes.name} - Integrity`;
        this.active_data = {};
        this.card_type = "";
        this.showIntegrity = true;
        this.showIntro = false;
      } else {
        this.key = key;
        if (this.menus && this.menus[key - 1]?.on_hold) {
          this.getMenuDetails(
            this.menus[key - 1].id,
            this.key - 1,
            this.externalApiUserLearnObjId
          );
          this.key = this.key - 1;
        } else {
          this.getMenuDetails(this.menus[key].id, this.key);
        }
      }
      if (this.menus && this.menus[key]?.on_hold) {
        this.holdOnMessage = this.menus[key]?.on_hold_message;
        this.show("holdingTask");
      }
    },
    completeDialogicLO() {
      this.hide("feedbackTaskFinal");
      const _fb = new FormData();
      _fb.append("user_learn_obj[complete]", true);
      api
        .updateLOStatus(
          this.active_data.attributes.user_section_id,
          this.active_data.id,
          _fb
        )
        .then(() => {
          this.scrollToContentBottom();
        })
        .catch((err) => {
          console.log(err);
          utilFunctionService.hideLoader();
        });
    },
    showCertificationOfCompletion() {
      this.showLoader();
      this.api.getCertificationOfCompletion().then( res => {
        this.hideLoader();
        this.$modal.$show('certificationOfCompletionModal', res)
      }).catch(err => {
        this.hideLoader();
        this.showerr(`${err}`);
      })
    },
    next(type, complete) {
      this.emailSubject = "";
      this.emailDesc = "";
      this.hide("feedbackTaskFinal");

      if (this.key === this.menus.length - 1 && !this.hasCompletedModule) {
        utilFunctionService.hideLoader();
        utilFunctionService.showerr(
          "Please complete all tasks to see your final feedback"
        );
      }
      if (this.card_type === "quiz" && !this.hasReviewedFeedback) {
        utilFunctionService.showerr(
          "Please review your feedback before proceeding to the next task"
        );
      } else {
        if (type !== "feedback") utilFunctionService.showLoader();
        const _fb = new FormData();
        _fb.append("user_learn_obj[complete]", !complete ? true : false);
        api
          .updateLOStatus(
            this.active_data.attributes.user_section_id,
            this.active_data.id,
            _fb
          )
          .then(() => {
            let learnContentAreaTop =
              document.getElementById("learn-content-area");
            learnContentAreaTop.scrollTo(0, 0);
            utilFunctionService.hideLoader();
            if (
              this.menus &&
              this.menus[this.key]?.on_hold &&
              this.percentage < 100
            ) {
              this.holdOnMessage = this.menus[this.key]?.on_hold_message;
              this.show("holdingTask");
            } else {
              if (type !== "feedback") {
                if (this.key === this.menus.length - 1) {
                  this.key = this.key + 1;
                }
                if (this.key === this.menus.length) {
                  this.showFeedBack();
                } else {
                  this.key = this.key + 1;
                  this.sideMenu();
                  this.getActive(this.key);
                }
              }
            }
          })
          .catch(() => {
            if (!type) utilFunctionService.hideLoader();
          });
      }
    },
    // Show feedback kicks in when the user clicks the last next button or if they click the final feedback button
    showFeedBack() {
      //console.log("*** showFeedback hit");
      utilFunctionService.showLoader();
      // Final evaluation assesses the entire module
      api
        .finalEvaluation(this.id)
        .then((res) => {
          if (res && res.data) {
            this.finalDebrief = res.data;
            this.enable_grading = this.finalDebrief.enable_grading;
            this.overall_grade_display_type =
              this.finalDebrief.overall_grade_display_type;
            this.overall_grade_label = this.finalDebrief.overall_grade_label;
            this.overall_grade_percentage =
              this.finalDebrief.overall_grade_percentage;
            this.finalDebrief.data.forEach((lo) => {
              if (
                (lo.card_type === "submission_email" ||
                  lo.card_type === "submission") &&
                //lo.overall_grade === "0.0"
                lo.published === false
              ) {
                this.disableReportBtn = true;
              }
            });
            utilFunctionService.hideLoader();
            // Here we are checking for MOAT
            const MOAT_LO_IDS = [563, 562, 561, 560, 557, 556, 554, 553, 552, 551, 549]
            const MOAT_LEARN_MOD_ID = this.menus[0].attributes.learning_object.attributes.learn_mod_id;
            console.log(MOAT_LEARN_MOD_ID)
            if (MOAT_LO_IDS.includes(MOAT_LEARN_MOD_ID)) {
              this.isMoatCustom = true;
            }
            if (this.isMoatCustom) {
              let dialogicFeedbackData = res.data.data.filter(item => item.card_type === "llm_dialogic");
              this.$modal.show("finalFeedbackLlmDialogicModal", 
                { 
                  feedbackData: dialogicFeedbackData,
                }
              );
            } else {
              this.$modal.show("finalFeedback", {
                pdfURL: this.finalDebrief.evaluation_url,
                student_id: this.userDetails.id,
                llm_dialogic_feedback: res.data,
              });
              }
          } else this.goDashBoard();
        })
        .catch((err) => console.log(err));
    },
    goDashBoard() {
      this.$router.push("/dashboard");
    },
    getChatAssessmentData(payload) {
      api.submitLlmChatAssessment(payload).then((res) => {
        console.log(res);
      });
    },
    sendChatMessages() {
      const _fb = new FormData();
      _fb.append("user_learn_obj_id", this.active_data.id);
      _fb.append("content", this.chatMessage);
      api
        .sendChatMessage(_fb)
        .then(() => {
          this.getChatMessages();
          this.chatMessage = "";
        })
        .catch(() => {
          this.getChatMessages();
          this.chatMessage = "";
        });
    },
    getChatMessages() {
      api
        .getChatMessages(this.active_data.id)
        .then((res) => {
          this.chatMessage = "";
          this.messageDate = res.message_start_date;
          this.chatMessages = [];
          this.chatMessages = res.data.data;
        })
        .catch(() => {
          this.chatMessages = [];
        });
      this.readSubmissionNotification();
    },
    readSubmissionNotification() {
      const _fd = new FormData();
      _fd.append("user_learn_obj_id", this.active_data.id);
      api
        .readSubmissionCount(_fd)
        .then((res) => {
          if (res.status === 200) {
            this.menus[this.key].attributes.learner_submissions_count = 0;
          }
        })
        .catch();
    },
    openChatMsg() {
      this.showChat = !this.showChat;
      if (this.menus[this.key].attributes.learner_messages_count > 0) {
        this.readMessage();
        this.menus[this.key].attributes.learner_messages_count = 0;
      }
    },
    readMessage() {
      const _fd = new FormData();
      _fd.append("user_learn_obj_id", this.active_data.id);
      api
        .readMessage(_fd)
        .then(() => {})
        .catch();
    },

    resetQuizInteraction() {
      api
        .resetQuizInteraction(this.questions[0].quizEvaluationId)
        .then(() => {
          this.$router.push({path: `/learning/${this.module_id}/${this.active_data.id}`})
          this.$router.go();
        })
        .catch(() => {});
    },

    getUserIntegrityAccept() {
      api.getIntegrityAccept(this.id).then((res) => {
        this.integrityStudentAgree =
          res.data.data.attributes.academic_integrity_agree;
        this.checkIntegrity();
      });
    },
  },
  created() {
    this.sideMenu();
    const user_data = this.userDetails;

    this.sso_authenticated = user_data.attributes.sso_authenticated;
    // this.getUserIntegrityAccept();
    window.history.scrollRestoration = "manual";
    // this.$cable.subscribe({
    //   channel: "AssessmentResultChannel",
    //   room: user_data.id,
    // });
  },
};
</script>

<style scoped lang="scss">
  .final-feedback-modal .modal-title {
    flex-direction: row;
  }
  .final-feedback-title {
    width: 50%;
  }
  .emailBody {
    :deep( p ) {
      margin: 0 !important;
    }
  }
  .grading {
    width: 30%;
    text-align: right;
    justify-content: flex-end;
  }
  .grading-p {
    font-size: 32px;
    margin-bottom: 16px;
  }
  .grading-progress {
    font-size: 14px;
    margin-top: 1em;
  }
  .regen-report {
    margin: 16px 0;
  }
  .intro-transcript {
    :deep( p ) {
      display: block !important;
    }
  }
  .quillWrapper {
    padding-top: 1em;
    .ql-editor {
      padding: 1em;
    }
  }
  .download-report {
    border-color: #ffffff;
  }
  .download-report:hover {
    background-color: #40a78f;
  }

</style>
